import React from 'react';
import styles from './ProfilePic.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../../Community/UserPic/UserPic';

import FileUpload from './FileUpload/FileUpload';

interface IUserPic {
  image?: string;
  onImageAdd?: () => void;
  onImageRemove?: () => void;
  editable?: boolean;
}

const ProfilePic: React.FC<IUserPic> = (props) => {
  const handleImageAdd = () => {
    if (props.onImageAdd) {
      return props.onImageAdd();
    } else return false;
  };

  const handleImageRemove = () => {
    if (props.onImageRemove) {
      return props.onImageRemove();
    } else return false;
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={() => handleImageAdd()}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
      </button>
    );
  };

  const AddArea = () => {
    return (
      <div className={`${styles.addArea}`}>
        <FileUpload onImageAdd={() => handleImageAdd()} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-blue`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div className={`${styles.profilePicWrapper} position-relative`}>
      <div className="position-relative">
        <UserPic image={props.image} />
        {!props.image && <AddArea />}
      </div>
      {props.image && props.editable && <RemoveButton />}
      {/* ${!props.image && props.editable ? 'justify-content-between' : 'justify-content-end'} */}
      <div className={`${styles.badges} d-flex justify-content-end`}>
        <div className="d-flex">{props.children}</div>
        {/* {!props.image && props.editable && <AddButton />} */}
      </div>
    </div>
  );
};

export default ProfilePic;
