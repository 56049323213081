import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/scss';
import 'swiper/css/autoplay';
import styles from './Slider.module.scss';

interface ISlider {
  timeInterval: number;
  children?: React.ReactNode[];
}

// https://swiperjs.com/react
/**
 * Must have at least two children
 * @param timeInterval auto swipte interval in ms
 * @returns
 */
const Slider: React.FC<ISlider> = (props) => {
  console.log(props.children);
  return (
    <section className={`container-fluid ${styles.sliderWrapper}`}>
      <div className="row">
        <div className="col-12">
          <Swiper
            className="desall-swiper"
            modules={[Autoplay]}
            slidesPerView={'auto'}
            centeredSlides={true}
            // centerInsufficientSlides={true}
            loop={true}
            autoplay={{
              delay: props.timeInterval,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {props.children?.map((item) => (
              <SwiperSlide>{item}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Slider;
