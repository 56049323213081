import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './FileUpload.module.scss';

interface IFileUpload {}

const FileUpload: React.FC<IFileUpload> = (props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [upFileList, setUpFileList] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log(acceptedFiles[0]);
    setFileToUpload(acceptedFiles[0]);
    setIsUploading(true);

    //mocked behavior
    setProgress(100);

    setUpFileList(acceptedFiles);
  }, []);

  const removeItem = () => {
    console.log('Emptying file list');
    setUpFileList([]);
    setIsUploading(false);
  };

  const DropZoneView = (props: { onDrop: (acceptedFiles: File[]) => void }) => {
    return (
      <Dropzone onDrop={(acceptedFiles) => props.onDrop(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className={`row ${styles.dropArea}`}>
            <div className="col-12 d-flex align-items-center justify-content-center" {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                Drag and drop an image or <span className="color-blue">choose a file</span> from your computer (allowed
                formats: .jpg, .png). Max 10 images.
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const FileList = (props: { uploadedFiles: File[] }) => {
    return (
      <>
        {props.uploadedFiles.map((file) => (
          <div className="row">
            <div className="col-3 d-flex py-3">
              <img
                className={`${styles.uploadPreviewImg}`}
                src="https://source.unsplash.com/collection/928423/480x480"
                alt=""
              />
            </div>
            <div className="col-9 col-md-6 d-flex align-items-center ps-5">{file.name}</div>
            <div className="col-12 col-md-3 d-flex justify-content-end align-items-center cursor-pointer">
              <div className="pe-4">Remove</div>
              <button className="btn-icon squared bg-red color-light" onClick={() => removeItem()}>
                <FontAwesomeIcon icon={faXmark as IconProp} className="text" />
              </button>
            </div>
          </div>
        ))}
      </>
    );
  };

  const UploadingView = (props: { progress: number }) => {
    const [barWidth, setBarWidth] = useState<number>(0);

    useEffect(() => {
      setBarWidth(props.progress);
    }, [props.progress]);

    return (
      <div className={`row ${styles.progressArea}`}>
        <div className="col-12 mb-5">{fileToUpload && fileToUpload.name}</div>
        <div className="col-12">
          <div className={`${styles.progressBarWrapper}`}>
            <div className={`${styles.progressBar}`} style={{ width: `${barWidth}%` }}></div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <button className={`${styles.cancelButton} color-blue`} onClick={() => setIsUploading(false)}>
            Cancel upload
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.uploadContainer} ${isUploading && styles.inProgress}`}>
        {isUploading ? <UploadingView progress={progress} /> : <DropZoneView onDrop={onDrop} />}
      </div>
      <FileList uploadedFiles={upFileList} />
    </>
  );
};

export default FileUpload;
