import React from 'react';
import styles from './SplitSection.module.scss';

interface ISplitSection {
  bgColorLeft?: string;
  bgColorRight?: string;
  itemLeft?: React.ReactNode;
  itemRight?: React.ReactNode;
}

/**
 *
 * @param props
 * @returns
 */
const SplitSection: React.FC<ISplitSection> = (props) => {
  return (
    <section
      className={`container-fluid ${styles.splitSectionWrapper} `}
      style={{
        backgroundColor: `${props.bgColorLeft ?? 'transparent'}`,
      }}
    >
      <div className="row">
        <div className="col-12 col-md-6 d-flex justify-content-end">
          <div className={` py-7 px-3 ${styles.halfSection}`}>{props.itemLeft}</div>
        </div>
        <div
          className="col-12 col-md-6 d-flex justify-content-start"
          style={{
            backgroundColor: `${props.bgColorRight ?? 'transparent'}`,
          }}
        >
          <div className={`py-7 px-3 ${styles.halfSection}`}>{props.itemRight}</div>
        </div>
      </div>
    </section>
  );
};

export default SplitSection;
