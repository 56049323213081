import React from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { topNavPages } from '../../mockdata/navPages';
import PageNavigation from '../../components/PageNavigation/PageNavigation';

import ProjectsNavigation, { PaginationSwitch } from '../../components/Community/ProjectsNavigation/ProjectsNavigation';

import footerImg from '../../assets/footer_placeholder_transparent.png';

import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import ContestRow from '../../components/Contest/ContestRow/ContestRow';
import ContestRowBig from '../../components/Contest/ContestRowBig/ContestRowBig';

const mockGalleryImages = [
  'https://source.unsplash.com/_HqHX3LBN18/800x680',
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/RFP4D5hGTB0/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const mockGalleryImagesHalf = [
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const Contest: React.FC = () => {
  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Contest"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container mb-5">
        <div className="row">
          <div className="col-12">
            <ProjectsNavigation />
          </div>
        </div>
        <div className="row">
          <ContestCard
            //image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={56}
            category="Tipology - Category"
            startDate="02 Giu 22"
            name="Karen White"
            private
            accesscode
            nda
            global
            status="standby"
          ></ContestCard>
          <ContestCard
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={56}
            category="Tipology - Category"
            startDate="02 Giu 22"
            name="Karen White"
            global
            status="ongoing"
            progress={30}
          ></ContestCard>
          <ContestCard
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={56}
            category="Tipology - Category"
            startDate="02 Giu 22"
            name="Karen White"
            private
            global
            status="voting"
          ></ContestCard>
          <ContestCard
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={56}
            category="Tipology - Category"
            startDate="02 Giu 22"
            name="Karen White"
            global
            status="closed"
          ></ContestCard>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PaginationSwitch />
          </div>
        </div>

        <div className="row mt-5">
          <ContestRow
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            contestTitle="A beautiful contest title on two rows"
            entries={56}
            award={600}
            category="Tipology - Category"
            startDate="02 Giu 22"
            clientName="Dondi"
            galleryImages={mockGalleryImages}
            status="ongoing"
            progress={60}
          ></ContestRow>
          <ContestRow
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            contestTitle="Contest title"
            entries={123}
            award={5600}
            category="Tipology - Category"
            startDate="12 Set 22"
            clientName="Chicco"
            galleryImages={mockGalleryImagesHalf}
            status="voting"
          ></ContestRow>
          <ContestRow
            contestTitle="Contest title"
            entries={123}
            award={5600}
            category="Tipology - Category"
            startDate="12 Set 22"
            clientName="Chicco"
            galleryImages={mockGalleryImagesHalf}
            status="voting"
          ></ContestRow>
        </div>

        <div className="row mt-3">
          <ContestRowBig
            image="https://source.unsplash.com/9-dkDEXWGzI/800x680"
            contestTitle="A beautiful contest title on many rows"
            entries={56}
            award={600}
            category="Tipology - Category"
            startDate="02 Giu 22"
            clientName="Dondi"
            status="ongoing"
            progress={60}
          ></ContestRowBig>
          <ContestRowBig
            image="https://source.unsplash.com/oKTPtfkbKKY/800x680"
            contestTitle="Contest title"
            entries={123}
            award={5600}
            category="Tipology - Category"
            startDate="12 Set 22"
            clientName="Chicco"
            status="closed"
          ></ContestRowBig>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Contest;
