import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

import ProjectsNavigation, { PaginationSwitch } from '../components/Community/ProjectsNavigation/ProjectsNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import DesignerCard from '../components/Community/DesignerCard/DesignerCard';
import DesignerRow from '../components/Community/DesignerRow/DesignerRow';

const mockGalleryImages = [
  'https://source.unsplash.com/_HqHX3LBN18/800x680',
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/RFP4D5hGTB0/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const mockGalleryImagesHalf = [
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const Community: React.FC = () => {
  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Community"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container mb-5">
        <div className="row">
          <div className="col-12">
            <ProjectsNavigation />
          </div>
        </div>
        <div className="row">
          <DesignerCard
            circle
            reputation={89}
            entries={21}
            awarded={850}
            userType="Standard User"
            role="Graphic Designer"
            location="Lisbon, Portugal"
            startDate="05 Mar 21"
            name="Pablo Diego José
            Francisco de Paula"
            //image="https://source.unsplash.com/KbBztc5PTC8/800x680"
          ></DesignerCard>
          <DesignerCard
            circle
            contest={21}
            entries={21}
            awarded={15000}
            location="Nice, France"
            role="Collective"
            userType="Group User"
            startDate="05 Mar 21"
            name="ILoveNice"
            image="https://source.unsplash.com/IReLVyNc6Nc/800x680"
          ></DesignerCard>
          <DesignerCard
            circle
            reputation={221}
            entries={21}
            awarded={21000}
            userType="Dream Team User"
            role="Architect"
            location="Vienna, Austria"
            startDate="05 Mar 21"
            name="Veronica Metzer"
            image="https://source.unsplash.com/fWPrABifLF4/800x680"
          ></DesignerCard>
          <DesignerCard
            circle
            contest={21}
            entries={21}
            awarded={1200}
            userType="Company"
            role="Automotive"
            location="Modena, Italy"
            startDate="05 Mar 21"
            name="Lamborghini"
            image="https://source.unsplash.com/ze5wHM9kplc/800x680"
          ></DesignerCard>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PaginationSwitch />
          </div>
        </div>

        <div className="row mt-5">
          <DesignerRow
            circle
            contest={21}
            entries={21}
            awarded={1200}
            userType="Dream team user"
            role="Graphic Designer"
            location="Modena, Italy"
            startDate="05 Mar 21"
            name="Lamborghini"
            image="https://source.unsplash.com/A73ah5JKtVA/800x680"
            galleryImages={mockGalleryImages}
          ></DesignerRow>
          <DesignerRow
            circle
            contest={21}
            entries={21}
            awarded={1200}
            userType="Standard User"
            role="Industrial Designer"
            location="Modena, Italy"
            startDate="05 Mar 21"
            name="Lamborghini"
            image="https://source.unsplash.com/TQSFD1cFgMk/800x680"
            galleryImages={mockGalleryImagesHalf}
          ></DesignerRow>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Community;
