import React from 'react';
import CardInfo, { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faUser, faTrophy, faHeart } from '@fortawesome/free-solid-svg-icons';

interface IProjectCard extends ICardInfo {
  title: string;
  category: string;
  contestTitle: string;
  startDate: string;
  name: string;
  votes: number;
}

const ProjectCard: React.FC<IProjectCard> = (props) => {
  return (
    <div className="col-12 col-md-6 col-lg-3 mb-4">
      <CardInfo image={props.image}>
        <div className="text-center">
          <h2 className="text-xl fw-bold color-blue mt-4 mb-4 px-3">{props.title}</h2>
          <div className="text mb-5">{props.category}</div>
          <div className="d-flex flex-column align-items-center">
            <div className="info" style={{ maxWidth: '65%' }}>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">{props.name}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start">Posted: {props.startDate}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">{props.contestTitle}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">{props.votes} votes</div>
              </div>
            </div>
          </div>
        </div>
      </CardInfo>
    </div>
  );
};

export default ProjectCard;
