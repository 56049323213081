import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import HorizontalSection from '../components/HorizontalSection/HorizontalSection';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';
import HeroSection from '../components/HeroSection/HeroSection';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCreativesPages } from '../mockdata/forCreativesPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import creative from '../assets/creative_placeholder.png';

const About: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Info for Creatives"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCreativesPages} />

      <HorizontalSection>
        <div className="row my-5">
          <div className="col-12 d-flex justify-content-center">
            <img className="img-550 fit-cover" alt="Placeholder" src={creative} />
          </div>
        </div>
      </HorizontalSection>

      <HorizontalSection bgColor="#36474F">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-light">About Us</h2>
            <h3 className="sub-title color-light">Subtitle</h3>

            <p className="bigger-text py-3 color-light">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua.
            </p>
            <p className="text-xl fw-bold pt-5 pb-3 color-light">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut.
            </p>
            <p className="text pt-5 pb-3 color-light">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi corporis voluptates dolor nulla iure
              accusantium perspiciatis qui magni explicabo excepturi id quidem, distinctio consequuntur autem eos minima
              ab accusamus fugit? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates explicabo, ad
              odit ducimus doloribus corporis hic ipsam iste magni culpa quidem cumque facere nisi consequuntur.
              Dignissimos autem voluptatem consequuntur in!
            </p>
          </div>
        </div>
      </HorizontalSection>

      <HeroSection
        minHeight="70vh"
        alpha={0}
        bgColor="#7997c5"
        bgImage="https://source.unsplash.com/random/1440x900?laptop,desk/"
      ></HeroSection>

      <SplitSectionImage
        bgColor="#ffffff"
        bgImage="https://source.unsplash.com/random/?city,night"
        reverse
        content={
          <div className="row py-7">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <h1 className="bold-title color-dark mb-7">Our mission</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8 text color-dark">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut
                  aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore
                  eu fugiat nulla pariatur.
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn-dark mt-7">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <SplitSectionImage
        bgColor="#36474F"
        bgImage="https://source.unsplash.com/random/?city,daylight"
        content={
          <div className="row py-7">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <h1 className="bold-title color-light mb-7">Our goals</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8 text color-light">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut
                  aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore
                  eu fugiat nulla pariatur.
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn-light mt-7">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <HorizontalSection bgColor="#f6f6f6">
        <div className="container py-7 px-0">
          <div className="row justify-content-center pb-3">
            <div className="COL-12 col-md-10">
              <h2 className="text-center bold-title">Our team</h2>
              <p className="text text-center mt-5">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt iste quod saepe dolor, blanditiis
                odit eaque velit molestiae quae corporis nulla nihil id accusantium. Quae laudantium magnam ab alias
                voluptates. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt ullam harum aut facere
                itaque, necessitatibus asperiores ea voluptatum hic
              </p>
            </div>
          </div>
        </div>
        <section className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/9347621/480x480"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/1215308/480x480"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/9347621/800x600"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/2347777/480x480"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/2219444/480x480"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">FNesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/2347777/480x480"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img
                className="img-270 "
                src="https://source.unsplash.com/collection/9347621/800x600"
                alt="Placeholder"
              />
              <h3 className="text-xl fw-bold pt-5 pb-3">Nesciunt iste quod saepe dolor</h3>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet quae sunt molestiae blanditiis.
              </p>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default About;
