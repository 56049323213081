import React from 'react';
import { Link } from 'react-router-dom';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCompanyPages } from '../mockdata/forCompanyPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import artsana from '../assets/artsana_placeholder.png';
import theme1 from '../assets/theme_1.png';
import theme2 from '../assets/theme_2.png';
import theme3 from '../assets/theme_3.png';
import theme4 from '../assets/theme_4.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

import HorizontalSection from '../components/HorizontalSection/HorizontalSection';
import SplitSection from '../components/SplitSection/SplitSection';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';
import CardLarge from '../components/CardLarge/CardLarge';

const OnMarket: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#7997c5"
        logoImg={headerLogo}
        bgImage={factory}
        pageTitle="Info for companies"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCompanyPages} />

      <HorizontalSection>
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-dark">Big text</h2>
            <h3 className="sub-title color-dark">Subtitle</h3>

            <p className="bigger-text py-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua.
            </p>
            <p className="text-xl fw-bold pt-5 pb-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut.
            </p>
            <p className="text pt-5 pb-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi corporis voluptates dolor nulla iure
              accusantium perspiciatis qui magni explicabo excepturi id quidem, distinctio consequuntur autem eos minima
              ab accusamus fugit? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates explicabo, ad
              odit ducimus doloribus corporis hic ipsam iste magni culpa quidem cumque facere nisi consequuntur.
              Dignissimos autem voluptatem consequuntur in!
            </p>
          </div>
        </div>
      </HorizontalSection>

      <div className="container">
        <div className="row mb-7">
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/928423/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/1691032/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/3829169/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/1326207/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/17461484/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/11722928/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/928423/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/1691032/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/3829169/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/1326207/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/17461484/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
          <div className="col-4 mb-4">
            <CardLarge backGround="https://source.unsplash.com/collection/11722928/480x480">
              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <div className="text-xxl fw-bold color-light mb-5">Product design: baby crib</div>
                  <div className="color-light text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, vero. Nam quo neque eius ipsum totam
                    accusantium corrupti doloremque. Alias odit et rerum deleniti ipsam debitis.
                  </div>
                  <div className="text-xxl fw-bold color-light mt-5">Client: Chicco</div>
                </div>
              </div>
            </CardLarge>
          </div>
        </div>
      </div>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default OnMarket;
