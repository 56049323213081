export const topNavPages: INavLink[] = [
  {
    text: 'Home',
    path: '/',
  },
  {
    text: 'Typography',
    path: '/typography',
  },
  {
    text: 'Images',
    path: '/images',
  },
  {
    text: 'Buttons',
    path: '/buttons',
  },
  {
    text: 'Cards',
    path: '/cards',
  },
  {
    text: 'Forms',
    path: '/forms',
  },
  {
    text: 'Components',
    path: '/components',
  },
];
