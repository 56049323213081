import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import SplitSection from '../components/SplitSection/SplitSection';
import HorizontalSection from '../components/HorizontalSection/HorizontalSection';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCreativesPages } from '../mockdata/forCreativesPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import creative from '../assets/creative_placeholder.png';
import Slider from '../components/Slider/Slider';
import CardLarge from '../components/CardLarge/CardLarge';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';

const AdditionalInfoCreatives: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Info for Creatives"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCreativesPages} />

      <SplitSection
        bgColorLeft="#ffffff"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Upload tips</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
            <div className="col-12">
              <button className="btn-dark rounded mt-7">See our tutorials</button>
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <section className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/8817103/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Importanza della prima immagine</h3>
            <p className="text">
              Access to a community of creatives coming from all around the world, with whom one can share the passion
              for design.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/1215308/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Uso di tutti gli slot immagini a disposizione</h3>
            <p className="text">International awareness generated by the interest of designers and professionals.</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/5008076/800x600" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Non utilizzare immagini coperte da copyright</h3>
            <p className="text">
              Chance to create a quality portfolio based on real-life scenarios, by taking part in the entire creative
              process.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/9572993/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Invio di più progetti</h3>
            <p className="text">
              Chance to get in touch with the best companies on the market, while running for cash awards and royalties.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/8817103/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Formato delle immagini</h3>
            <p className="text">
              Access to a community of creatives coming from all around the world, with whom one can share the passion
              for design.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/1215308/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Soggetti grandi e su fondo neutro</h3>
            <p className="text">International awareness generated by the interest of designers and professionals.</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/5008076/800x600" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Limitare la presenza di testi all’interno delle immagini</h3>
            <p className="text">
              Chance to create a quality portfolio based on real-life scenarios, by taking part in the entire creative
              process.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/9572993/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Viste ambientate e prodotto in uso</h3>
            <p className="text">
              Chance to get in touch with the best companies on the market, while running for cash awards and royalties.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/8817103/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Descrizioni</h3>
            <p className="text">
              Access to a community of creatives coming from all around the world, with whom one can share the passion
              for design.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/1215308/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Materiali aggiuntivi</h3>
            <p className="text">International awareness generated by the interest of designers and professionals.</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/5008076/800x600" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">Hidden option</h3>
            <p className="text">
              Chance to create a quality portfolio based on real-life scenarios, by taking part in the entire creative
              process.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 " src="https://source.unsplash.com/collection/9572993/480x480" alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">XXX</h3>
            <p className="text">
              Chance to get in touch with the best companies on the market, while running for cash awards and royalties.
            </p>
          </div>
        </div>
      </section>

      <SplitSection
        bgColorLeft="#ededed"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Desall Concept revision</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
            <div className="col-12">
              <button className="btn-dark rounded mt-7">Get in touch</button>
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <SplitSectionImage
        bgColor="#3b3c3c"
        bgImage="https://source.unsplash.com/random/?city,night"
        content={
          <div className="row py-7">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <h1 className="bold-title color-light mb-7">Quality standards and consistency with the brief</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8 text color-light">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut
                  aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore
                  eu fugiat nulla pariatur.
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn-light mt-7">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <SplitSection
        bgColorLeft="#ededed"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Post-contest revisions</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <SplitSectionImage
        bgColor="#3b3c3c"
        bgImage="https://source.unsplash.com/random/?talking"
        content={
          <div className="row py-7">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <h1 className="bold-title color-light mb-7">Riservatezza, ecc...</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8 text color-light">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut
                  aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore
                  eu fugiat nulla pariatur.
                </div>
              </div>
            </div>
          </div>
        }
      />

      <SplitSection
        bgColorLeft="#ededed"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Option right</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <HorizontalSection bgColor="#ffffff">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-dark mb-3">FAQ</h2>

            <p className="bigger-text py-3 color-dark">Desall from A to Z</p>
          </div>
        </div>
        <section className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="sub-title color-dark">Account</h3>
            </div>
          </div>
          <div className="row mb-5">
            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What should I choose between User and Client account?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                “User” account should be chosen by those who are planning to join a contest and submit a project. This
                type of account allows voting. Note well, User accounts are not allowed to launch contests. “Client”
                account is for those (either Individual or Companies), who want to launch a contest themselves. This
                type of account allows voting. Note well, Client accounts are not allowed to join contests. Individual
                Client accounts are conceived for privates, without a company, who want to launch a contest. Company
                Client accounts are conceived for companies that want to launch a contest.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What are the Facebook and Twitter connects options?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                If you like, you can signup in Desall by connecting to your Facebook or Twitter accounts. After granting
                Desall access to Facebook or Twitter, you will be able to choose your preferred username. Note well that
                you cannot edit the e-mail address. In case of the Twitter connect option you will also have to confirm
                your account by clicking on the link provided in the Activation e-mail. (Check also your SPAM folder).
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What should I use for my username?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                Users are free to choose their username as long as they do not harm the sensibility of the Community by
                using bad or offensive language. In case the username has already been taken, you will be prompted an
                error message. We suggest you NOT to use your full e-mail address as username, in order to preserve your
                privacy.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">I did not receive the activation e-mail. What should I do?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                Before contacting us, check your SPAM folder inside your inbox and look for our e-mail. If our e-mail
                has been flagged as SPAM, please unflag it and add noreply[at]desall[dot]com and info[at]desall[dot]com
                to your contact list to make sure you will receive further communications. If you still cannot see it,
                write us to hello[at]desall[dot]com and we will be glad to assist you.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">Why on earth has the Activation e-mail been flagged as SPAM?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                Sometimes e-mail providers flag as SPAM messages coming from unknown recipients. If this is your case,
                please unflag it and add noreply[at]desall[dot]com and info[at]desall[dot]com to your contact list.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark"> I forgot my password and/or username.</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                No need to worry. Go to the login page and write your e-mail in the “Forgot username or password?”
                field.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark"> What are the mandatory fields for the Signup?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The mandatory fields for Signup are: Username, Email, Password, Repeat Password. You must tick the
                checkbox “I’m over 18 and agree to the Privacy Policy & User Agreement”.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark"> Can I have more than one account?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                No, users can have one account all life long. If there are troubles with your account, please contact us
                to hello[at]desall[dot]com.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">How can I delete my account?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                Simply get in contact with us writing a request email clicking on the following link. A member of our
                staff will get in contact with you as soon as possible.
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="sub-title color-dark">Profile</h3>
            </div>
          </div>
          <div className="row mb-5">
            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What is the profile page for?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The profile page is a section where you can edit your avatar, personal information, including a short
                bio, tags, your own website, social network, etc. Our suggestion is to fill in all fields, including a
                nice picture, to catch the attention of our Clients.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What are the mandatory fields to browse Desall.com?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                No fields are mandatory. However we suggest you to complete your profile.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What are the mandatory fields to be able to vote?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                No fields are mandatory. However we suggest you to complete your profile.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">
                What are the mandatory fields to upload a project for a contest?
              </div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The mandatory fields to upload a project are shown in the drop down menu named “Upload your design? Fill
                in the mandatory fields *”, in the bottom of the profile page. They are: Name, Address, City, State (for
                those countries with no States, write your province/region/...), Zip Code (postal code), Country. You
                can decide which of the following fields you want to show or not in your public profile page by checking
                the “public” check-box: Birth year, Name, City, Country.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">How can I change my password?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                If you want to change your password, go to the “Password” tab in your profile page.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">How can I change my notifications/newsletter settings?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                If you want to change your notifications/newsletter settings, go to the “Notifications” tab in your
                profile page.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What is my “Portfolio” tab?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The “Porfolio” tab is a section of your profile page where all the projects submitted for a contest are
                listed. Works created for purposes other than the contests held on Desall cannot be uploaded. If you
                have an online portfolio on other platforms, you can write the URL in the website field from your
                “Profile” page.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What is my “Favorites” tab?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The “Favorites” tab is a section of your profile page where you can see all users/clients, designs and
                contests that you have picked as favourite, as well as the users/clients that have favourited you.m.
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What is my “Badge” tab?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The “Badge” tab is a section of your profile page where you can see your achievements. The most active
                you are, the most badges you get!
              </p>
            </div>

            {/* Question */}
            <div className="col-12">
              <div className="text fw-bold text-dark">What is my “Awards” tab?</div>
            </div>
            <div className="col-12">
              <p className="text text-dark py-2">
                The “Awards” tab is a section of your profile page where you can see the awards you have won joining our
                contests.
              </p>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default AdditionalInfoCreatives;
