import React from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faUser, faTrophy, faHeart, faPencil } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestCard.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';

interface IContestCard extends ICardInfo {
  title: string;
  category: string;
  contestTitle: string;
  startDate: string;
  name: string;
  votes: number;
  status: ContestStatus;
  progress?: number;

  private?: boolean;
  accesscode?: boolean;
  nda?: boolean;
  global?: boolean;
}

const ContestCard: React.FC<IContestCard> = (props) => {
  return (
    <div className="col-12 col-md-6 col-lg-3 mb-4 position-relative">
      <div className={`${styles.cardInfoWrapper} d-flex flex-column bg-light`}>
        <div
          className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
            props.circle && 'px-5 pt-4'
          } position-relative`}
        >
          {props.image ? (
            <SquaredImage image={props.image} altText={props.altText} circle={props.circle} />
          ) : (
            <div className={`${styles.squaredImageWrapper}`}>
              <div className={`${styles.imageInner}`}>
                <Spinner />
              </div>
            </div>
            // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
          )}
          <ActionButton
            label="Save as favorite"
            icon="addFavorite"
            position="top-right"
            action={() => alert('Added as favorite')}
          />
          <MultiActionButtonsWrapper position="bottom-left">
            {props.private && <ActionButton label="Private gallery" icon="private" />}
            {props.accesscode && <ActionButton label="Access code" icon="accesscode" />}
            {props.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
            {props.global && <ActionButton label="Global contest" icon="global" />}
          </MultiActionButtonsWrapper>
        </div>
        <StatusBar status={props.status} progress={props.progress} />
        <div className={`${styles.cardInfoContent} bg-light`}>
          <div className="text-center">
            <h2 className="text-xl fw-bold color-blue mt-4 mb-4 px-3">{props.title}</h2>
            <div className="text mb-5">{props.category}</div>
            <div className="d-flex flex-column align-items-center">
              <div className="info" style={{ maxWidth: '65%' }}>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.name}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start">Posted: {props.startDate}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.contestTitle}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.votes} votes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestCard;
