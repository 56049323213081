import React from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import ActionButton from '../../ActionButton/ActionButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusBar from '../StatusBar/StatusBar';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faPencil,
  faTrophy,
  faIndustry,
  faEarthAmericas,
  faEyeSlash,
  faKey,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRowBig.module.scss';

interface IContestRowBig extends ICardInfo {
  category: string;
  contestTitle: string;
  startDate: string;
  clientName: string;
  entries: number;
  award: number;

  status: ContestStatus;
  progress?: number;
}

const ContestRowBig: React.FC<IContestRowBig> = (props) => {
  return (
    <div className={`${styles.ContestRowBigWrapper} col-12 mb-5 bg-light position-relative`}>
      <ActionButton
        position="top-right"
        label="Add to favorites"
        action={() => alert('Add to favorites')}
        icon="addFavorite"
      />

      <div className="row">
        {/* Cover Image  ====================================================================*/}
        <div className="col-3 ps-0 d-flex">
          <div
            className={`${
              props.image ? styles.ContestRowBigImage : styles.ContestRowBigImagePlaceholder
            } bg-light-gray position-relative`}
          >
            {props.image ? <img src={props.image} alt="Project Cover" /> : null}
            <div className={styles.statusBar}>
              <StatusBar status={props.status} progress={props.progress} />
            </div>
          </div>
        </div>
        {/* Content Info  ====================================================================*/}
        <div className="col-9 p-5 ps-4">
          <div className="row">
            <div className="col-12">
              <h2 className="text-xl fw-bold color-blue">{props.contestTitle}</h2>
              <div className="mt-3 text">{props.category}</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
              <span className="text text-start color-blue">{props.clientName}</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex flex-wrap">
              <div className="pe-5 me-3 mb-3">
                <button className="btn-icon squared bg-blue color-light me-3">
                  <FontAwesomeIcon icon={faEarthAmericas as IconProp} className="text" />
                </button>
                <span className="text text-start">global contest</span>
              </div>
              <div className="pe-5 me-3 mb-3">
                <button className="btn-icon squared bg-blue color-light me-3">
                  <FontAwesomeIcon icon={faEyeSlash as IconProp} className="text" />
                </button>
                <span className="text text-start">private gallery</span>
              </div>
              <div className="pe-5 me-3 mb-3">
                <button className="btn-icon squared bg-blue color-light me-3">
                  <FontAwesomeIcon icon={faKey as IconProp} className="text" />
                </button>
                <span className="text text-start">access code</span>
              </div>
              <div className="pe-5 me-3 mb-3">
                <button className="btn-icon squared bg-blue color-light me-3">
                  <FontAwesomeIcon icon={faLock as IconProp} className="text" />
                </button>
                <span className="text text-start">accept nda</span>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex flex-wrap">
              <div className="pe-5 me-3 mb-3">
                <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                <span className="text-xl fw-bold text-start color-success">Ends: {props.startDate}</span>
              </div>
              <div className="pe-5 me-3 mb-3">
                <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                <span className="text-xl fw-bold text-start color-blue">{props.entries} entries</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                <span className="text-xl fw-bold text-start color-blue">€ {props.award} award</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestRowBig;
