import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faMobileScreenButton, faDisplay } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitterSquare,
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
} from '@fortawesome/free-brands-svg-icons';

import styles from './AppFooter.module.scss';

interface IFooter {
  links: INavLink[];
  bgImage?: string;
  bgColor?: string;
  logoImg?: string;
}

const FooterNav = ({ title, links }: { title: string; links: INavLink[] }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={`${styles.footerNav} col-12 col-4 col-md-3 col-lg-2 text-center text-md-start`}>
      <div
        className={`${styles.footerNavTitle} text color-blue font-body fw-bold pb-3 mt-4`}
        onClick={() => setOpen(!open)}
      >
        {title}
      </div>
      <ul className={`pb-0 pb-md-5 ${open && styles.opened}`}>
        {links.map((navLink, index) => (
          <li key={'footerNav_' + index} className="py-1">
            <Link className="text color-light font-body fw-bold" to={navLink.path}>
              {navLink.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

//TODO add breadcrumbs (https://www.npmjs.com/package/use-react-router-breadcrumbs)
const AppFooter: React.FC<IFooter> = (props) => {
  return (
    <div
      className={`${styles.footerWrapper} container-fluid px-0`}
      style={{
        backgroundColor: props.bgColor,
        backgroundImage: `url(${props.bgImage})`,
      }}
    >
      <div className={`${styles.footerContentWrapper} container-md pt-5`}>
        <div className="row ">
          <FooterNav title="About Us" links={props.links} />
          <FooterNav title="Info for creatives" links={props.links} />
          <FooterNav title="Info for companies" links={props.links} />
          <FooterNav title="Rules" links={props.links} />
          <FooterNav title="Products" links={props.links} />
          <FooterNav title="Resources" links={props.links} />
        </div>
      </div>
      <div className={`${styles.footerSocialWrapper} container-fluid`}>
        <div className="container ">
          <div className="row my-3 py-4">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
              <div className="text color-light fw-bold text-nowrap me-5">Follow us</div>
              <div className={`text-xxl color-light fw-bold  d-flex justify-content-end ps-3`}>
                <a href="http://www.facebook.com/desallcom" target="_blank" rel="noreferrer" className="me-4">
                  <FontAwesomeIcon icon={faFacebookSquare as IconProp} className="color-light" />
                </a>
                <a href="http://instagram.com/desallcom" target="_blank" rel="noreferrer" className="me-4">
                  <FontAwesomeIcon icon={faInstagramSquare as IconProp} className="color-light" />
                </a>
                <a href="https://twitter.com/#!/desallcom" target="_blank" rel="noreferrer" className="me-4">
                  <FontAwesomeIcon icon={faTwitterSquare as IconProp} className="color-light" />
                </a>
                <a href="https://www.linkedin.com/company/desall" target="_blank" rel="noreferrer" className="me-4">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} className="color-light" />
                </a>
                <a href="http://pinterest.com/desallcom/" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faPinterestSquare as IconProp} className="color-light" />
                </a>
              </div>
            </div>
            <div className="col12 col-md-6 d-flex pt-4 pt-md-0 justify-content-center justify-content-md-end">
              <div className="addthis_inline_share_toolbox"></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.footerContentWrapper} container-md pt-3 pb-3`}>
        <div className="row mb-7">
          <div className={`col-12 col-md-2 ${styles.footerLogo} d-flex justify-content-center d-md-block`}>
            <img src={props.logoImg} alt="Desall" />
          </div>
          <div className="col-12 col-md-10 pt-4 pt-md-0 d-flex flex-column justify-content-center align-items-center d-md-block">
            <div className="text color-light fw-bold py-1 d-flex align-items-center justify-content-start">
              <div className={`${styles.contactIconWrapper} d-flex justify-content-center`}>
                <FontAwesomeIcon icon={faEnvelope as IconProp} className="text color-light" />
              </div>
              <div>hello@desall.com</div>
            </div>
            <div className="text color-light fw-bold py-1 d-flex align-items-center justify-content-start">
              <div className={`${styles.contactIconWrapper} d-flex justify-content-center`}>
                <FontAwesomeIcon icon={faMobileScreenButton as IconProp} className="text color-light" />
              </div>

              <div>+39 0422 162.69.15</div>
            </div>
            <div className="text color-light fw-bold py-1 d-flex align-items-center justify-content-start">
              <div className={`${styles.contactIconWrapper} d-flex justify-content-center`}>
                <FontAwesomeIcon icon={faDisplay as IconProp} className="text color-light" />
              </div>

              <div>www.desall.com</div>
            </div>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-12 text color-light fw-bold text-center text-md-start">
            © 2021 Desall , All rights reserved | Desall S.r.l. is a sole-shareholder company, subject to the direction
            and coordination of Factory08 S.r.l. C.F. e P. Iva 04469720264
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
