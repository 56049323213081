import React from 'react';
import styles from './ProfileHeader.module.scss';
import { hexToRGB } from '../../../helpers/utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import FileUpload from './FileUpload/FileUpload';

interface IProfileHeader {
  bgImage?: string;
  bgColor?: string;
  alpha?: number;
  onImageAdd?: () => void;
  onImageRemove?: () => void;
  minHeight?: string;
  editable?: boolean;
}

/**
 * Profile header
 * @param {string} bgImage Background image
 * @param {string} bgColor Background color and overlay color in HEX
 * @param {string} alpha Alpha da 0 a 1, riferito all'overlay sopra l'immagine
 * @returns
 */
const ProfileHeader: React.FC<IProfileHeader> = (props) => {
  let gradientString = '';
  let colorString = 'transparent';
  if (props.bgColor) {
    let c = hexToRGB(props.bgColor);
    colorString = `rgba(${c[0]},${c[1]},${c[2]}, 1)`;
    gradientString = `linear-gradient(rgba(${c[0]},${c[1]},${c[2]}, ${props.alpha ?? 0}), rgba(${c[0]},${c[1]},${
      c[2]
    }, ${props.alpha ?? 0})),`;
  }

  const handleImageAdd = () => {
    if (props.onImageAdd) {
      return props.onImageAdd();
    } else return false;
  };

  const handleImageRemove = () => {
    if (props.onImageRemove) {
      return props.onImageRemove();
    } else return false;
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={() => handleImageAdd()}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
      </button>
    );
  };

  const AddArea = () => {
    return <FileUpload onImageAdd={() => handleImageAdd()} />;
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-blue`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div
      className={`${styles.profileHeaderWrapper} container d-flex flex-row justify-content-center align-items-stretch position-relative p-0`}
      style={{
        minHeight: `${props.minHeight ?? '30rem'}`,
        backgroundImage: `${gradientString} url(${props.bgImage})`,
        backgroundColor: `${colorString}`,
      }}
    >
      <div className="container p-0">
        {!props.bgImage && props.editable && <AddArea />}
        {props.bgImage && props.editable && <RemoveButton />}
      </div>
    </div>
  );
};

export default ProfileHeader;
