export const topNavPages: INavLink[] = [
    {
      text: "Home",
      path: "/",
    },
    {
      text: "Contest",
      path: "/contest",
    },
    {
      text: "Community",
      path: "/community",
    },
    {
      text: "Companies",
      path: "/companies",
    },
    {
      text: "Creatives",
      path: "/creatives",
    },
  ];