import React from 'react';
import styles from './TimeLine.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIndustry, faUsers, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import { ITimeLine } from './timelineInterfaces';
import TLHLP from './timeline.helper';

import Timer from './components/Timer';
import ProgressSection from './components/ProgressSection';

const TimeLine: React.FC<ITimeLine> = (props) => {
  const uploadProgress: number = TLHLP.getProgress(props.progress.upload);
  const communityProgress: number | undefined = props.progress.community
    ? TLHLP.getProgress(props.progress.community)
    : undefined;
  const clientProgress: number = TLHLP.getProgress(props.progress.client);

  let isClosed: boolean = uploadProgress + (communityProgress ?? 100) + clientProgress === 300;

  return (
    <div className={`row ${styles.timelineContainer}`}>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} px-0 d-flex`}>
          <Timer leftDays={TLHLP.getLeftDays([props.progress])} />

          <div
            className={`col-3 g-0 flex-grow-1 ${styles.progressWrapper} ${styles.upload} ${isClosed && styles.closed}`}
          >
            <ProgressSection
              progress={uploadProgress}
              label="Upload Phase"
              icon={faCloudArrowUp as IconProp}
              iconLabel="concept design"
              daysLeft={TLHLP.getDaysLeft(props.progress.upload)}
              active={TLHLP.isUploadActive(props.progress)}
            />
          </div>

          {communityProgress !== undefined && (
            <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.community} ${isClosed && styles.closed}`}>
              <ProgressSection
                progress={communityProgress}
                label="Vote"
                icon={faUsers as IconProp}
                iconLabel="community vote"
                daysLeft={props.progress.community ? TLHLP.getDaysLeft(props.progress.community) : 0}
                active={TLHLP.isCommunityactive(props.progress)}
              />
            </div>
          )}
          <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.client} ${isClosed && styles.closed}`}>
            <ProgressSection
              progress={clientProgress}
              label="Client Vote"
              icon={faIndustry as IconProp}
              iconLabel="client vote"
              daysLeft={TLHLP.getDaysLeft(props.progress.client)}
              active={TLHLP.isClientActive(props.progress)}
            />
          </div>
          <div className={`col flex-shrink-1 ${styles.progressWrapper} ${styles.endSection} m-0 p-0`}>
            {isClosed && <ProgressSection progress={100} label="Closed" closed={true} daysLeft={0} active={true} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
