import React from 'react';
import { format } from 'fecha';
import styles from './MiniTimeLine.module.scss';
import { Transition } from 'react-transition-group';

import { ITimeLine } from './timelineInterfaces';
import TLHLP from './timeline.helper';

const MiniTimeLine: React.FC<ITimeLine> = (props) => {
  const uploadProgress: number = TLHLP.getProgress(props.progress.upload);
  const communityProgress: number | undefined = props.progress.community
    ? TLHLP.getProgress(props.progress.community)
    : undefined;
  const clientProgress: number = TLHLP.getProgress(props.progress.client);

  //Active phase
  let isClosed: boolean = uploadProgress + (communityProgress ?? 100) + clientProgress === 300;
  let uploadActive: boolean =
    TLHLP.isUploadActive(props.progress) &&
    !TLHLP.isCommunityactive(props.progress) &&
    !TLHLP.isClientActive(props.progress) &&
    !isClosed;

  let communityActive: boolean =
    communityProgress !== undefined &&
    TLHLP.isCommunityactive(props.progress) &&
    !TLHLP.isClientActive(props.progress) &&
    !isClosed;
  let clientActive: boolean = TLHLP.isClientActive(props.progress) && !isClosed;

  const getPhaseName = () => {
    if (uploadActive) {
      return 'Upload Phase';
    }
    if (communityActive) {
      return 'Vote Phase';
    }
    if (clientActive) {
      return 'Client Vote Phase';
    }
    if (isClosed) {
      return 'Closed';
    }
  };

  const ProgressBar = (props: { perc: number }) => {
    const animationDuration = 800;

    let transitions: any = {
      entering: {
        width: 0,
        opacity: 1,
      },
      entered: {
        opacity: 1,
        width: props.perc + '%',
      },
      exiting: {
        opacity: 0,
        width: 0,
      },
      exited: {
        opacity: 0,
        width: 0,
      },
    };

    return (
      <Transition in={true} appear={true} timeout={animationDuration}>
        {(state) => <div className={`${styles.progressBar}`} style={{ ...transitions[state] }}></div>}
      </Transition>
    );
  };

  return (
    <div className={`row mx-0 ${styles.timelineContainer} py-4`}>
      <div className="col-12 text-center pb-1 text-uppercase text-xl">{getPhaseName()}</div>
      <div className="col-12 text-center pb-3">ends: {format(new Date(), 'dd MMM YYYY')}</div>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} d-flex`}>
          <div
            className={`col-12 px-0 ${styles.progressWrapper} ${uploadActive && styles.upload} ${
              communityActive && styles.community
            } ${clientActive && styles.client} ${isClosed && styles.closed}`}
          >
            {uploadActive && <ProgressBar perc={uploadProgress} />}
            {communityActive && <ProgressBar perc={communityProgress ?? 0} />}
            {clientActive && <ProgressBar perc={clientProgress} />}
            {isClosed && <ProgressBar perc={100} />}
          </div>
        </div>
      </div>
      {/* Timer */}
      <div className={`col-12 bg-light color-dark d-flex justify-content-center align-items-center text-center py-4`}>
        <div className="px-3">
          <div className="text-xl fw-bold">{TLHLP.getLeftDays([props.progress])}</div>
          <div>days</div>
        </div>
        <div className="d-none d-lg-flex px-3 px-md-0 px-lg-3 ps-5">
          <div className="px-2">
            <div className="text-xl fw-bold">21</div>
            <div>hrs</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">33</div>
            <div>min</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">16</div>
            <div>sec</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniTimeLine;
