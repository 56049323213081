import React from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import ContestStats from '../../components/Community/ContestStats/ContestStats';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faIndustry, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { topNavPages } from '../../mockdata/navPages';
import PageNavigation from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';
import TimeLine from '../../components/TimeLine/TimeLine';
import ActionButton, { MultiActionButtonsWrapper } from '../../components/ActionButton/ActionButton';
import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';

let tl1 = {
  upload: {
    total: 50,
    passed: 20,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

let minitl1 = {
  upload: {
    total: 50,
    passed: 50,
  },
  community: {
    total: 25,
    passed: 15,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

const Brief: React.FC = () => {
  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Brief"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className={`container pt-5 mb-5`}>
        {/* Head ====================================================================== */}
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="position-relative">
              <img
                className="img-fluid rounded mt-5"
                src="https://source.unsplash.com/collection/9572993/480x480"
                alt="Placeholder"
              />
              <MultiActionButtonsWrapper position="bottom-left" rounded>
                <ActionButton label="Private gallery" icon="private" />
              </MultiActionButtonsWrapper>
            </div>
          </div>
          <div className="col-12 col-md-9 pt-5">
            <div className="row">
              <div className="info-main mb-5 col-12">
                <h1 className="sub-title color-dark">Laundry Tool Design</h1>
                <div className="text color-dark">Product Design - Healtcare</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="info">
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text">Reckitt Benckiser</div>
                  </div>
                  <div className="d-flex justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text">San Francisco, USA</div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text">Ends 04 Mar 19</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9 mt-5 mt-md-0">
                <ContestStats entries={34} inspirations={12} awards={7500} votes={240} />
              </div>
              <div className="col-12 mb-3 d-none d-md-flex">
                <div className="container">
                  <TimeLine progress={tl1} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Body ====================================================================== */}
        <div className="row">
          <div className="col-12 col-md-3 position-relative">
            <SideActionsCard>
              <button className="btn-blue rounded full mb-3">Add to favorite</button>
              <button className="btn-blue rounded full mb-3">Have a question?</button>
              <MiniTimeLine progress={minitl1} />

              <button className="btn-success big full rounded">
                <FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="text color-light" />
                Upload
              </button>
            </SideActionsCard>
            <div className="tags mt-7 d-flex flex-wrap">
              <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-9 pt-5">
            <div className="row">
              <div className="col-12 ps-4">
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Contest typology</div>
                  <div className="col-12 col-md-10">
                    Single phase (
                    <a href="http://www.google.com" target="_blank" rel="noreferrer">
                      What’s this?
                    </a>
                    ). Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam,
                    nisi ut aliquid ex ea commodi consequatur.
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Name</div>
                  <div className="col-12 col-md-10">Karen JuliaWhite</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Summary</div>
                  <div className="col-12 col-md-10">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt? Lorem ipsum dolor sit amet consectetur adipisicing elit. In omnis enim
                      molestias voluptate recusandae minus cupiditate expedita eveniet officiis quo molestiae atque,
                      numquam pariatur nihil repellendus, nesciunt aliquam quam deserunt.
                    </p>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Company description</div>
                  <div className="col-12 col-md-10">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt? Lorem ipsum dolor sit amet consectetur adipisicing elit. In omnis enim
                      molestias voluptate recusandae minus cupiditate expedita eveniet officiis quo molestiae atque,
                      numquam pariatur nihil repellendus, nesciunt aliquam quam deserunt.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt?
                    </p>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-6 col-md-4">
                    <button className="btn-blue rounded full mb-3">Add to favorite</button>
                    <button className="btn-blue rounded full mb-3">Have a question?</button>
                    <button className="btn-success big full rounded">
                      <div className="btn-icon">
                        <FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="text color-light" />
                      </div>
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Brief;
