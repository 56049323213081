import React from 'react';
import styles from './UserPic.module.scss';

interface IUserPic {
  image?: string;
  onImageAdd?: () => void;
  onImageRemove?: () => void;
}

const UserPic: React.FC<IUserPic> = (props) => {
  return (
    <div className={`${styles.userPicWrapper}`}>
      <div className={`${styles.userPicInner}`}>{props.image && <img src={props.image} alt="" />}</div>
    </div>
  );
};

export default UserPic;
