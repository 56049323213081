import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './ActionButton.module.scss';
import {
  faEarthEurope,
  faEyeSlash,
  faKey,
  faLock,
  faPlus,
  faMinus,
  faCheck,
  faMedal,
} from '@fortawesome/free-solid-svg-icons';

interface IMultiActionButtonsWrapper {
  position?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
  rounded?: boolean;
}

interface IActionButton {
  position?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
  label?: string;
  action?: () => void;
  icon: 'private' | 'addFavorite' | 'removeFavorite' | 'accesscode' | 'global' | 'acceptnda' | 'confirm' | 'award';
}

const icons = {
  private: faEyeSlash,
  addFavorite: faPlus,
  removeFavorite: faMinus,
  accesscode: faKey,
  global: faEarthEurope,
  acceptnda: faLock,
  confirm: faCheck,
  award: faMedal,
};

/**
 * Per il posizionamento deve essere contenuto in un div con specificato position-relative
 * @param label string
 * @param position top-left | top-right | bottom-right | bottom-left
 * @param icon private | addFavorite | removeFavorite | accesscode | global | acceptnda | confirm | award
 * @returns
 */
const ActionButton: React.FC<IActionButton> = (props) => {
  let positionStyles: string;
  let labelLeft: boolean = false;

  switch (props.position) {
    case 'top-left':
      positionStyles = styles.topLeft;
      labelLeft = true;
      break;
    case 'top-right':
      positionStyles = styles.topRight;
      break;
    case 'bottom-right':
      positionStyles = styles.bottomRight;
      break;
    case 'bottom-left':
      positionStyles = styles.bottomLeft;
      labelLeft = true;
      break;
    default:
      positionStyles = styles.topRight;
      break;
  }

  return (
    <div
      className={`${styles.actionButtonWrapper} ${positionStyles} ${props.action ? styles.hasAction : null} d-flex ${
        labelLeft ? '' : 'flex-row-reverse'
      }`}
      onClick={props.action}
    >
      <button className={`${styles.actionButton}`}>
        <FontAwesomeIcon icon={icons[props.icon] as IconProp} className="text" />
      </button>
      {props.label && <div className={`${styles.buttonLabel} text`}>{props.label}</div>}
    </div>
  );
};

/**
 * Per il posizionamento deve essere contenuto in un div con specificato position-relative
 * @param rounded boolean
 * @param position top-left | top-right | bottom-right | bottom-left
 * @returns
 */

export const MultiActionButtonsWrapper: React.FC<IMultiActionButtonsWrapper> = (props) => {
  let positionStyles: string;

  switch (props.position) {
    case 'top-left':
      positionStyles = styles.wrapperTopLeft;

      break;
    case 'top-right':
      positionStyles = styles.wrapperTopRight;

      break;
    case 'bottom-right':
      positionStyles = styles.wrapperBottomRight;

      break;
    case 'bottom-left':
      positionStyles = styles.wrapperBottomLeft;

      break;
    default:
      positionStyles = styles.wrapperTopRight;
      break;
  }

  return (
    <div className={`${styles.multiActionButtonsWrapper} ${positionStyles}`}>
      <div className={`${styles.multiActionButtonsContainer} ${props.rounded ? styles.rounded : ''}`}>
        {
          // Passa la prop.position del parent come prop ai children
          React.Children.map(props.children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { position: props.position });
            }
          })
        }
      </div>
    </div>
  );
};

export default ActionButton;
