import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './custom.scss';

// ROUTES
import { ROUTEPATHS } from './routes/routePaths';
import App from './App';
import Signup from './routes/signup';

import Contest from './routes/contest/Contest';
import Brief from './routes/contest/Brief';
import Community from './routes/community';
import Profile from './routes/profile/Profile';
import ProfileEditable from './routes/profile/ProfileEditable';
import Portfolio from './routes/profile/Portfolio';
import Design from './routes/profile/Design';
import InfoCompanies from './routes/infoCompanies';
import OnMarket from './routes/market';
import InfoCreatives from './routes/infoCreatives';
import AdditionalInfoCreatives from './routes/creatives-additional-info';
import DreamTeam from './routes/creatives-dream-team';
import About from './routes/about';
import Partners from './routes/partners';

import Library from './routes/library';
import Typography from './routes/typography';
import Images from './routes/images';
import Buttons from './routes/buttons';
import Cards from './routes/cards';
import Forms from './routes/forms';
import Components from './routes/components';

import CompaniesCaseStudies from './routes/companiesCaseStudies';

ReactDOM.render(
  <HashRouter>
    <Routes>
      <Route path={ROUTEPATHS.HOME} element={<App />} />
      <Route path={ROUTEPATHS.SIGNUP} element={<Signup />} />

      {/* Component Library */}
      <Route path={ROUTEPATHS.LIBRARY} element={<Library />} />
      <Route path={ROUTEPATHS.TYPOGRAPHY} element={<Typography />} />
      <Route path={ROUTEPATHS.IMAGES} element={<Images />} />
      <Route path={ROUTEPATHS.BUTTONS} element={<Buttons />} />
      <Route path={ROUTEPATHS.CARDS} element={<Cards />} />
      <Route path={ROUTEPATHS.FORMS} element={<Forms />} />
      <Route path={ROUTEPATHS.COMPONENTS} element={<Components />} />

      {/* Main pages */}
      <Route path={ROUTEPATHS.COMMUNITY} element={<Community />} />
      <Route path={ROUTEPATHS.INFO_COMPANIES} element={<InfoCompanies />} />

      {/* Contest */}
      <Route path={ROUTEPATHS.CONTEST} element={<Contest />} />
      <Route path={ROUTEPATHS.CONTEST_BRIEF} element={<Brief />} />

      {/* Creatives */}
      <Route path={ROUTEPATHS.INFO_CREATIVES} element={<InfoCreatives />} />
      <Route path={ROUTEPATHS.INFO_CREATIVES_ADDITIONAL} element={<AdditionalInfoCreatives />} />
      <Route path={ROUTEPATHS.INFO_CREATIVES_DREAMTEAM} element={<DreamTeam />} />
      <Route path={ROUTEPATHS.INFO_CREATIVES_ABOUT} element={<About />} />
      <Route path={ROUTEPATHS.INFO_CREATIVES_PARTNERS} element={<Partners />} />

      {/* Companies */}
      <Route path={ROUTEPATHS.COMPANIES_CASE_STUDIES} element={<CompaniesCaseStudies />} />
      <Route path={ROUTEPATHS.COMPANIES_MARKET} element={<OnMarket />} />

      {/* Community */}
      <Route path={ROUTEPATHS.COMMUNITY_PROFILE} element={<Profile />} />
      <Route path={ROUTEPATHS.COMMUNITY_PROFILE_EDITABLE} element={<ProfileEditable />} />
      <Route path={ROUTEPATHS.COMMUNITY_PORTFOLIO} element={<Portfolio />} />
      <Route path={ROUTEPATHS.COMMUNITY_DESIGN} element={<Design />} />
    </Routes>
    {/* <React.StrictMode>
      <App />
    </React.StrictMode> */}
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
