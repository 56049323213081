import React from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import ProfileHeader from '../../components/Profile/ProfileHeader/ProfileHeader';
import ContestStats from '../../components/Community/ContestStats/ContestStats';
import UserPic from '../../components/Community/UserPic/UserPic';
import ProjectCard from '../../components/Community/ProjectCard/ProjectCard';
import ProjectRow from '../../components/Community/ProjectRow/ProjectRow';
import ProjectsNavigation, { PaginationSwitch } from '../../components/Community/ProjectsNavigation/ProjectsNavigation';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faCircle, faMapMarkerAlt, faFlask } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { topNavPages } from '../../mockdata/navPages';
import PageNavigation from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './Profile.module.scss';

const mockGalleryImages = [
  'https://source.unsplash.com/_HqHX3LBN18/800x680',
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/RFP4D5hGTB0/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const mockGalleryImagesHalf = [
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const Portfolio: React.FC = () => {
  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Profile"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className={`${styles.profileWrapper} container pt-5 mb-5`}>
        <div className="row">
          <div className="col-12">
            <ProfileHeader bgImage="https://source.unsplash.com/pEgsWN0kwbQ/1200x600" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className={`${styles.profilePic}`}>
              <UserPic image="https://source.unsplash.com/3TLl_97HNJo/480x480" />
            </div>
            <div className={`${styles.badges} d-flex justify-content-end`}>
              <div className={`${styles.badge} bg-red color-light`}>
                <FontAwesomeIcon icon={faFlask as IconProp} />
              </div>
              <div className={`${styles.badge} bg-green color-light`}>
                <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2 pt-5">
            <div className="info-main mb-7">
              <h1 className="sub-title color-blue">Karen White</h1>
              <div className="text color-dark">Industrial designer</div>
            </div>

            <div className="info">
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">San Francisco, USA</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">since 04 Mar 19</div>
              </div>
            </div>
            <div className="badges">
              <div className="d-flex justify-content-start">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCircle as IconProp} className=" text color-green me-3" />
                </div>
                <div className="text color-green">Dream Team user</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 mt-5">
            <ContestStats
              entries={34}
              inspirations={12}
              awards={7500}
              contests={3}
              reputation={135}
              favorites={267}
              votes={240}
              comments={123}
            />
          </div>
        </div>
        {/* Projecst ============================================================================= */}
        <ProjectsNavigation />
        <div className="row">
          <ProjectCard
            image="https://source.unsplash.com/_HqHX3LBN18/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={56}
            category="Tipology - Category"
            startDate="02 Giu 22"
            name="Karen White"
          ></ProjectCard>
          <ProjectCard
            title="Contest title"
            contestTitle="Furnishing Creative
            Lighting Award"
            votes={21}
            category="Tipology - Category"
            startDate="05 Mar 21"
            name="Pablo Diego José
            Francisco de Paula"
          ></ProjectCard>
          <ProjectCard
            title="Nia - A very very very
            long design title"
            contestTitle="Contest title"
            votes={14}
            category="Tipology - Category"
            startDate="13 Apr 01"
            name="Mina Rossetti"
          ></ProjectCard>
          <ProjectCard
            image="https://source.unsplash.com/Ry9WBo3qmoc/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={123}
            category="Tipology - Category"
            startDate="28 Nov 19"
            name="Joseph Mattermost"
          ></ProjectCard>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PaginationSwitch />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <ProjectRow
              image="https://source.unsplash.com/_HqHX3LBN18/800x680"
              title="Contest title"
              contestTitle="Contest title"
              votes={56}
              category="Tipology - Category"
              startDate="02 Giu 22"
              name="Karen White"
              galleryImages={mockGalleryImages}
            ></ProjectRow>
            <ProjectRow
              title="Nia - A very very very
            long design title"
              contestTitle="Contest title"
              votes={14}
              category="Tipology - Category"
              startDate="13 Apr 01"
              name="Mina Rossetti"
              galleryImages={mockGalleryImagesHalf}
            ></ProjectRow>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Portfolio;
