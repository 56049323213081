import React from 'react';
import { Link } from 'react-router-dom';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCompanyPages } from '../mockdata/forCompanyPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import creative from '../assets/creative_placeholder.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

import HorizontalSection from '../components/HorizontalSection/HorizontalSection';
import SplitSection from '../components/SplitSection/SplitSection';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';
import HeroSection from '../components/HeroSection/HeroSection';

const CompaniesCaseStudies: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#7997c5"
        logoImg={headerLogo}
        bgImage={factory}
        pageTitle="Case Studies"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCompanyPages} />

      {/* Custom solutions */}
      <section className="container py-7">
        <div className="row">
          <div className="col-12 text-center pb-7">
            <h3 className="bold-title color-dark pb-3">Custom solutions</h3>
            <p className="text text-center">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, doloribus quibusdam quas ullam perspiciatis
              repellat quod repudiandae ad animi placeat praesentium nulla velit dicta! Voluptas laudantium
              necessitatibus unde qui et. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illo sunt, ea unde
              odit blanditiis adipisci, cum praesentium facilis porro voluptas eos.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">Lorem ipsum dolor sit amet</h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel nemo molestiae iste perferendis vitae
              doloribus.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">Lorem ipsum dolor sit amet</h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel nemo molestiae iste perferendis vitae
              doloribus.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">Lorem ipsum dolor sit amet</h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel nemo molestiae iste perferendis vitae
              doloribus.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">Lorem ipsum dolor sit amet</h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel nemo molestiae iste perferendis vitae
              doloribus.
            </p>
          </div>
        </div>
      </section>

      <HorizontalSection bgColor="#7997c5">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-light">Workshop</h2>
            <h3 className="sub-title color-light">Subtitle</h3>

            <p className="bigger-text py-3 color-light">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua.
            </p>
            <p className="sub-title pt-5 pb-3 color-light">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </HorizontalSection>

      <HeroSection
        minHeight="70vh"
        alpha={0}
        bgColor="#7997c5"
        bgImage="https://source.unsplash.com/collection/1034275/1440x900"
      ></HeroSection>

      <SplitSection
        bgColorLeft="#7997c5"
        itemLeft={<img src={factory} className="img-fluid" alt="Placeholder" />}
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <h2 className="bold-title color-light">Offline Develop</h2>
            <p className="text color-light mt-5">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis corporis reprehenderit, iusto aliquid quidem
              repellendus blanditiis necessitatibus unde laudantium placeat tempora consequatur, sint nesciunt
              voluptatem expedita tempore harum ad. Delectus.
            </p>
          </div>
        }
      />

      <SplitSectionImage
        bgColor="#ffffff"
        bgImage="https://source.unsplash.com/random/?city,night"
        reverse
        content={
          <div className="row py-7">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <h1 className="bold-title color-dark mb-7">On the market</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8 text color-dark">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut
                  aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore
                  eu fugiat nulla pariatur.
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn-dark mt-7">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <SplitSection
        bgColorLeft="#f6f6f6"
        itemLeft={
          <div className="row">
            <div className="col-12 col-md-6">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
            <div className="col-12 col-md-6">
              <img src={creative} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <h2 className="bold-title color-dark mb-7">International award</h2>
            <p className="text color-dark mt-5">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis corporis reprehenderit, iusto aliquid quidem
              repellendus blanditiis necessitatibus unde laudantium placeat tempora consequatur, sint nesciunt
              voluptatem expedita tempore harum ad. Delectus.
            </p>
          </div>
        }
      />

      <HorizontalSection bgColor="#ffffff">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-dark">Innovation platform</h2>
            <h3 className="sub-title color-dark">Subtitle</h3>

            <p className="bigger-text py-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua.
            </p>
            <p className="sub-title pt-5 pb-2 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisci elit.
            </p>
          </div>
        </div>
      </HorizontalSection>

      <HeroSection
        minHeight="90vh"
        alpha={0}
        bgColor="#ffffff"
        bgImage="https://source.unsplash.com/random/1440x900/?imac"
      ></HeroSection>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default CompaniesCaseStudies;
