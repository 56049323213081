import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './SideActionsCard.module.scss';

interface ISideActionsCard {
  anchor?: boolean;
}

const SideActionsCard: React.FC<ISideActionsCard> = (props) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [shouldAnchor, setShouldAnchor] = useState<boolean>(false);

  const handleScroll = () => {
    //cardRef.current && console.log(cardRef.current.getBoundingClientRect().top);
    let vDistance = window.pageYOffset;

    if (cardRef.current) {
      console.log(cardRef.current.getBoundingClientRect().top, vDistance);
      if (cardRef.current.getBoundingClientRect().top < 110 && vDistance > 550) {
        setShouldAnchor(true);
      }
      if (vDistance < 550) {
        setShouldAnchor(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={cardRef} className={`mt-5 bg-light px-4 py-5 radius-m ${shouldAnchor ? styles.anchor : null}`}>
      {props.children}
    </div>
  );
};

export default SideActionsCard;
