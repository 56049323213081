import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIndustry, faUsers, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Buttons: React.FC = () => {
  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Buttons" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container page-container pb-5">
        <div className="row py-2 bg-secondary">
          <div className="col">
            <button className="btn-light">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-light rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-light" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-light rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-dark">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-dark rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-dark" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-dark rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-dark-blue">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-dark-blue rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-dark-blue" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-dark-blue rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-blue">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-blue rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-blue" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-blue rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-red">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-red rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-red" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-red rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-yellow">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-yellow rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-yellow" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-yellow rounded" href="#">
              Link
            </a>
          </div>
        </div>

        <div className="row py-2">
          <div className="col">
            <button className="btn-green">Button</button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-green rounded">Button</button>
            </div>
          </div>
          <div className="col">
            <a className="btn-green" href="#">
              Link
            </a>
          </div>
          <div className="col">
            <a className="btn-green rounded" href="#">
              Link
            </a>
          </div>
        </div>
        <div className="row py-2 bg-secondary mt-3">
          <h2 className="subtitle color-dark">Alternate</h2>
        </div>

        <div className="row py-2 bg-secondary">
          <div className="col">
            <button className="btn-success big full rounded">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="text color-light" />
              </div>
              Upload
            </button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-warning big full rounded">Button big</button>
            </div>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-danger big full rounded">Button big</button>
            </div>
          </div>
        </div>

        <div className="row py-2 bg-secondary">
          <div className="col">
            <button className="btn-success full rounded">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faCloudArrowUp as IconProp} className="text color-light" />
              </div>
              Upload
            </button>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-warning  full rounded">Button big</button>
            </div>
          </div>
          <div className="col">
            <div className="col">
              <button className="btn-danger  full rounded">Button big</button>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Buttons;
