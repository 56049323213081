import React, { useState } from 'react';
import UserPic from '../Community/UserPic/UserPic';
import styles from './Conversation.module.scss';
import Message from './Message';

interface IConversation {
  author: IUser;
  messages: IMessage[];
}

export interface IMessage {
  id: string;
  author: IUser;
  date: string;
  text: string;
  replies?: IMessage[];
}

const getTotalComments = (messages: IMessage[]) => {
  //TODO
  return messages.length;
};

const Conversation: React.FC<IConversation> = (props) => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div className={`row ${styles.conversationWrapper} ${opened ? styles.opened : ''}`}>
      <div className="col-12">
        <div className="row">
          <div className="d-none d-md-block col-3 col-md-1 mt-5">
            <UserPic image={props.author.profilePic} />
          </div>
          <div className="col-12 col-md-11">
            <div className="row">
              <div className="col-12 mb-3 d-flex justify-content-start align-items-center">
                <div className="text fw-bold color-dark">
                  This design has {getTotalComments(props.messages)} comments
                </div>
                <div
                  className={`ps-3 ${styles.conversationToggle} text fw-bold color-blue d-flex justify-content-start align-items-center`}
                  onClick={() => setOpened(!opened)}
                >
                  <div className={`${styles.arrow}`}></div>
                  <span className={`${styles.label}`}>{opened ? 'Hide' : 'Show'}</span>
                </div>
              </div>
              <div className="col-12">
                <textarea name="" cols={80} rows={10} placeholder="Write a message"></textarea>
              </div>
              <div className="col-12 mt-3">
                <button className="btn-dark-blue rounded">Post</button>
              </div>
            </div>
          </div>
        </div>
        <div className={`row pt-5 ${styles.conversationContent}`}>
          {props.messages.map((message) => (
            <Message {...message} canReply={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Conversation;
