import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Icons
import {
  IconDefinition,
  faPencil,
  faLightbulb,
  faDollarSign,
  faTrophy,
  faStar,
  faHeart,
  faBookmark,
  faComment,
} from '@fortawesome/free-solid-svg-icons';

import styles from './contestStats.module.scss';

interface IContestStats {
  entries?: number;
  inspirations?: number;
  awards?: number;
  contests?: number;
  reputation?: number;
  favorites?: number;
  votes?: number;
  comments?: number;
}

interface IStatsItem {
  icon: IconDefinition;
  name: string;
  value: number | string;
}

const StatsItem = (props: IStatsItem) => {
  return (
    <div className={`col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center mb-4 px-3`}>
      <div className="text fw-bold w-100 d-flex justify-content-start align-items-center text-left">
        <FontAwesomeIcon icon={props.icon as IconProp} className="text color-blue mt-1 me-4" /> {props.name}
      </div>
      <div className="sub-title color-blue text-center pt-2">{props.value}</div>
    </div>
  );
};
const ContestStats: React.FC<IContestStats> = (props) => {
  return (
    <div className={`row ${styles.statsWrapper}`}>
      {props.entries && <StatsItem name="entries" value={props.entries} icon={faPencil} />}
      {props.inspirations && <StatsItem name="inspirations" value={props.inspirations} icon={faLightbulb} />}
      {props.awards && <StatsItem name="awards won" value={'€ ' + props.awards} icon={faDollarSign} />}
      {props.contests && <StatsItem name="contests won" value={props.contests} icon={faTrophy} />}
      {props.reputation && <StatsItem name="reputation" value={props.reputation} icon={faStar} />}
      {props.favorites && <StatsItem name="added to favorites" value={props.favorites} icon={faBookmark} />}
      {props.votes && <StatsItem name="votes received" value={props.votes} icon={faHeart} />}
      {props.comments && <StatsItem name="comments received" value={props.comments} icon={faComment} />}
    </div>
  );
};

export default ContestStats;
