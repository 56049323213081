import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import { hexToRGB } from '../../helpers/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faBars } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../Community/UserPic/UserPic';

interface IHeader {
  links: INavLink[];
  bgColor: string;
  bgImage?: string;
  alpha?: number;
  logoImg?: string;
  pageTitle?: string;
}

const TopNav = ({ links }: { links: INavLink[] }) => {
  return (
    <ul className={`${styles.headerTopNav} d-block d-lg-flex justify-content-start pt-5 mt-2 pt-lg-0 mt-lg-2 mb-0`}>
      {links.map((navLink, index) => (
        <li key={'topnavlink_' + index} className="mb-5 mb-lg-0">
          <Link className="text color-light font-heading d-block d-lg-flex" to={navLink.path}>
            {navLink.text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const BreadCrumbs = () => {
  return (
    <div className={`col-12 text color-light fw-bold" ${styles.headerBreadCrumbs}`}>
      <Link className="text color-light font-heading" to={'/'}>
        Home
      </Link>{' '}
      /{' '}
      <Link className="text color-light font-heading" to={'/'}>
        Breadcrumbs Example
      </Link>
    </div>
  );
};

const ToggleButton = (props: { opened: boolean; onToggle: () => void }) => {
  return (
    <button className={`${styles.openCloseButton}`} onClick={() => props.onToggle()}>
      {props.opened ? (
        <FontAwesomeIcon icon={faClose as IconProp} className="bigger-text color-light" />
      ) : (
        <FontAwesomeIcon icon={faBars as IconProp} className="bigger-text color-light" />
      )}
    </button>
  );
};

interface IUserArea {
  isLogged: boolean;
  user: IUser;
  onSignUp: () => void;
  onSignIn: () => void;
}

const UserArea: React.FC<IUserArea> = (props) => {
  return props.isLogged ? (
    <div className="col-6 col-md-3 d-flex flex-column justify-content-start justify-content-md-center pt-5 pt-md-0">
      <div
        className={`${styles.profileWrapper} d-flex flex-column flex-md-row justify-content-end align-items-center align-items-md-start position-relative`}
      >
        <div className="d-flex justify-content-end align-items-center position-relative">
          <div className="text fw-bold color-light pe-3">Welcome!</div>
          <div className={`${styles.profilePic} position-relative`}>
            <UserPic image={props.user.profilePic} />
          </div>
        </div>
        <div className={`${styles.profileActions} px-0 pt-3 pt-md-0 ps-0 ps-md-4 p-lg-5`}>
          <div className={`${styles.profileName} text-xxl fw-bold pb-3`}>{props.user.username}</div>
          <div className={`${styles.profileMail} text mb-5 mb-md-4`}>{props.user.email}</div>
          <div className="pb-3">
            <Link to={'/profile'} className={`${styles.profileLink} text d-block`}>
              Profile
            </Link>
          </div>
          <div>
            <Link to={'/signup'} className={`${styles.profileLink} text d-block`}>
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12 col-md-3 d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center justify-content-md-end my-5 my-lg-0">
        <button className="btn-dark rounded me-5" onClick={() => props.onSignUp()}>
          Signup
        </button>
        <button className="btn-dark rounded" onClick={() => props.onSignIn()}>
          Login
        </button>
      </div>
    </div>
  );
};

//TODO add breadcrumbs (https://www.npmjs.com/package/use-react-router-breadcrumbs)
const AppHeader: React.FC<IHeader> = (props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [opened, setOpened] = useState<boolean>(false);

  const handleScroll = () => {
    let position = window.pageYOffset;
    setScrollPosition(position);
  };

  const mockUser = {
    username: 'Anna Salting',
    profilePic: 'https://source.unsplash.com/rDEOVtE7vOs/480x480',
    email: 'anna.salting@mailtesst.com',
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let c = hexToRGB(props.bgColor);

  const handleOpenCloseHeader = () => {
    console.log(opened);
    setOpened(!opened);
  };

  const handleSignUp = () => {
    alert('Signup');
  };

  const handleSignIn = () => {
    alert('Signin');
  };

  return (
    <div className={`${styles.headerWrapper} container-fluid px-0`} style={{ backgroundColor: props.bgColor }}>
      {/* Top Row ============================================================================== */}
      <div
        className={`container-fluid ${styles.headerTopRowContainer} pt-3 pb-3 ${opened ? styles.opened : ''}`}
        style={{ backgroundColor: scrollPosition > 50 || opened ? props.bgColor : 'transparent' }}
      >
        <div className="container-md">
          <div className={`row ${styles.headerTopRow} pt-3 pb-3 ${opened ? styles.opened : ''}`}>
            <div className={`col-10 col-lg-2 ${styles.headerLogo}`}>
              <img src={props.logoImg} alt="Desall" />
            </div>
            <div className="col-2 d-flex d-lg-none justify-content-end">
              <ToggleButton opened={opened} onToggle={handleOpenCloseHeader} />
            </div>
            <div className="col-12 col-lg-10">
              <div className="row justify-content-between">
                <div className={`${styles.navigationWrapper} col-6 col-md-7 d-flex flex-column justify-content-center`}>
                  <TopNav links={props.links} />
                </div>
                <UserArea isLogged={true} onSignIn={handleSignIn} onSignUp={handleSignUp} user={mockUser} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Row ============================================================================== */}
      <div
        className={`${styles.headerContentWrapper} container-md pb-7`}
        style={{
          backgroundImage: `
          linear-gradient(rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8}), 
          rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8})), 
          url(${props.bgImage})
          `,
        }}
      >
        {/* Bottom Row */}
        <div className="row mt-5">
          <BreadCrumbs />
        </div>
        <div className="row">
          <div className="col-12 light-title color-light">{props.pageTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
