import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './FileUpload.module.scss';

interface IFileUpload {
  onImageAdd: () => void;
}

const FileUpload: React.FC<IFileUpload> = (props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [progress, setProgress] = useState<number>(0);

  const mockProgress = () =>
    new Promise((resolve, reject) => {
      setProgress(100);

      setTimeout(() => {
        resolve(true);
      }, 2000);
    });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      console.log(acceptedFiles[0]);
      setFileToUpload(acceptedFiles[0]);
      setIsUploading(true);

      //mocked behavior
      await mockProgress().then(() => props.onImageAdd());
    },
    [props]
  );

  const DropZoneView = (props: { onDrop: (acceptedFiles: File[]) => void }) => {
    return (
      <Dropzone onDrop={(acceptedFiles) => props.onDrop(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className={`row ${styles.dropArea}`}>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center" {...getRootProps()}>
              <input {...getInputProps()} />
              <button className={`${styles.addButton} bg-blue mb-3`}>
                <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
              </button>

              <div>Drag and drop an image or choose a file from your computer (allowed formats: .jpg, .png).</div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  const UploadingView = (props: { progress: number }) => {
    const [barWidth, setBarWidth] = useState<number>(0);

    useEffect(() => {
      setBarWidth(props.progress);
    }, [props.progress]);

    return (
      <div className={`row ${styles.progressArea}`}>
        <div className="col-12 mb-5">{fileToUpload && fileToUpload.name}</div>
        <div className="col-12">
          <div className={`${styles.progressBarWrapper}`}>
            <div className={`${styles.progressBar}`} style={{ width: `${barWidth}%` }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.uploadContainer} ${isUploading && styles.inProgress}`}>
        {isUploading ? <UploadingView progress={progress} /> : <DropZoneView onDrop={onDrop} />}
      </div>
    </>
  );
};

export default FileUpload;
