import React from 'react';
import styles from './ProjectsNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTableCells, faTableList, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DropDown from '../../Forms/DropDown/DropDown';

interface IProjecNavigation {}

export const LayoutSwitch = () => {
  return (
    <div className={`${styles.layoutSwitch} bg-light py-2 px-2`}>
      <button className="btn-icon active">
        <FontAwesomeIcon icon={faTableCells as IconProp} className="text" />
      </button>
      <button className="btn-icon">
        <FontAwesomeIcon icon={faTableList as IconProp} className="text" />
      </button>
    </div>
  );
};

export const PaginationSwitch: React.FC = () => {
  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2`}>
      <button className="btn-icon">
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="text" />
      </button>
      <button className="btn-icon active">1</button>
      <button className="btn-icon">2</button>
      <button className="btn-icon">3</button>
      <button className="btn-icon">4</button>
      <button className="btn-icon">5</button>
      <button className="btn-icon">
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
      </button>
    </div>
  );
};

let filterCategories = [
  {
    label: 'Product Design',
    value: '1',
  },
  {
    label: 'Brand Identity',
    value: '2',
  },
  {
    label: 'Interface Design',
    value: '3',
  },
];

let filterSort = [
  {
    label: 'Date',
    value: '1',
  },
  {
    label: 'Votes',
    value: '2',
  },
  {
    label: 'Title',
    value: '3',
  },
];

const ProjectsNavigation: React.FC<IProjecNavigation> = (props) => {
  return (
    <div className="row py-4 mt-4">
      <div className="col-12 col-md-6 d-flex flex-column flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 w-100 mb-3 mb-lg-0">
          <DropDown label="Category" options={filterCategories} thin />
        </div>
        <div className="w-100">
          <DropDown label="Sort by" options={filterSort} thin />
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-end">
        <div className="me-5">
          <PaginationSwitch />
        </div>
        <div>
          <LayoutSwitch />
        </div>
      </div>
    </div>
  );
};

export default ProjectsNavigation;
