import React from 'react';
import { Link } from 'react-router-dom';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCompanyPages } from '../mockdata/forCompanyPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import artsana from '../assets/artsana_placeholder.png';
import theme1 from '../assets/theme_1.png';
import theme2 from '../assets/theme_2.png';
import theme3 from '../assets/theme_3.png';
import theme4 from '../assets/theme_4.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

import HorizontalSection from '../components/HorizontalSection/HorizontalSection';
import SplitSection from '../components/SplitSection/SplitSection';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';

const InfoCompanies: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#7997c5"
        logoImg={headerLogo}
        bgImage={factory}
        pageTitle="Info for companies"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCompanyPages} />

      <SplitSection
        bgColorLeft="#ffffff"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">International community</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />
      <SplitSection
        bgColorLeft="#E5E5E5"
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Wide range of solutions</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemLeft={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />
      <SplitSection
        bgColorLeft="#ffffff"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Feedback and market research</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />
      <SplitSection
        bgColorLeft="#E5E5E5"
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Brand awareness and talent scouting</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
          </div>
        }
        itemLeft={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      {/* Numbers */}
      <section className="container py-7">
        <div className="row">
          <div className="col-12 text-center pb-7">
            <h3 className="bold-title color-dark">Numbers</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="bigger-text pt-5 pb-3">+130000</h3>
            <p className="text-xxl px-2">members of the community</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="bigger-text pt-5 pb-3">+280</h3>
            <p className="text-xxl px-2">countries reached on a global scale</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="bigger-text pt-5 pb-3">+200</h3>
            <p className="text-xxl px-2">average of projects submitted per contest</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="bigger-text pt-5 pb-3">+290</h3>
            <p className="text-xxl px-2">promo channels and universities network</p>
          </div>
        </div>
      </section>

      {/* How it works */}
      <section className="container py-7">
        <div className="row">
          <div className="col-12 text-center pb-7">
            <h3 className="bold-title color-dark">How it works</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">1 - Brief definition</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">2 - Innovation and design research</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">3 - Market insight</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="sub-title pt-5 pb-3">4 - Selection and development</h3>
          </div>
        </div>
      </section>

      <HorizontalSection bgColor="#f6f6f6">
        <div className="container py-7 px-0">
          <div className="row justify-content-center pb-5">
            <div className="COL-12 col-md-10">
              <h2 className="text-center bold-title">What we do</h2>
              <h3 className="text-center sub-title color-light-gray-3">Subtitle</h3>
              <p className="text text-center">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt iste quod saepe dolor, blanditiis
                odit eaque velit molestiae quae corporis nulla nihil id accusantium. Quae laudantium magnam ab alias
                voluptates. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt ullam harum aut facere
                itaque, necessitatibus asperiores ea voluptatum hic
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Creating partnerships between companies</h3>
              <p>
                Two brands that are so different yet so complementary: Desall links Alessi’s design with Italcementi’s
                know-how in order to design an innovative centrepiece, entirely made of cement.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Focus and increase the brand awareness</h3>
              <p>
                SIDA (Shenzhen Industrial Design Profession Association) relied on Desall to bring awareness to its
                annual Fair. The final result is not only a gadget, but it is also an “event within the event” able to
                create engagement on both the brand and the exhibition.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Development of highly complex products</h3>
              <p>
                Design is just the first step: brands like Stiga rely on Desall also for the subsequent phases of the
                project, from product engineering to prototyping, working in synergy until the pre-industrialisation
                phase.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Creating partnerships between companies</h3>
              <p>
                Two brands that are so different yet so complementary: Desall links Alessi’s design with Italcementi’s
                know-how in order to design an innovative centrepiece, entirely made of cement.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Focus and increase the brand awareness</h3>
              <p>
                SIDA (Shenzhen Industrial Design Profession Association) relied on Desall to bring awareness to its
                annual Fair. The final result is not only a gadget, but it is also an “event within the event” able to
                create engagement on both the brand and the exhibition.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img
                className="img-370 squared"
                src="https://source.unsplash.com/collection/874140/480x480"
                alt="Product"
              />
              <h3 className="sub-title pt-5 pb-3">Development of highly complex products</h3>
              <p>
                Design is just the first step: brands like Stiga rely on Desall also for the subsequent phases of the
                project, from product engineering to prototyping, working in synergy until the pre-industrialisation
                phase.
              </p>
            </div>
          </div>
        </div>
      </HorizontalSection>

      <section>
        <SplitSectionImage
          bgColor="#E5E5E5"
          bgImage={artsana}
          content={
            <div className="row py-7">
              <div className="col-12">
                <h1 className="bold-title color-dark mb-7">
                  Case study <br /> Artsana Group
                </h1>
              </div>
              <div className="col-12 col-lg-8 text color-dark">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
                Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem
                adipisci eveniet enim illo.
              </div>
            </div>
          }
        />
      </section>

      <HorizontalSection bgColor="#f6f6f6">
        <div className="container py-7 px-0">
          <div className="row justify-content-center pb-5">
            <div className="COL-12 col-md-10">
              <h2 className="text-center bold-title">What we do</h2>
              <h3 className="text-center sub-title color-light-gray-3">Subtitle</h3>
              <p className="text text-center">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt iste quod saepe dolor, blanditiis
                odit eaque velit molestiae quae corporis nulla nihil id accusantium. Quae laudantium magnam ab alias
                voluptates. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt ullam harum aut facere
                itaque, necessitatibus asperiores ea voluptatum hic
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img className="img-270 fit-contain" src={theme1} alt="Product" />
              <h2 className="bigger-text pt-5 pb-2">Team</h2>
              <h3 className="sub-title pt-2 pb-4 h-75">Our best designers at your service</h3>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis alias, nemo, in magnam atque
                aspernatur maxime, repellendus natus itaque cupiditate dignissimos nostrum optio.
              </p>
              <button className="btn-blue mt-4">More info</button>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img className="img-270 fit-contain" src={theme2} alt="Product" />
              <h2 className="bigger-text pt-5 pb-2">Global</h2>
              <h3 className="sub-title pt-2 pb-4 h-75">The innovation process most used by our clients</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat, vero reiciendis tempora molestias
                repellendus quaerat quia ab incidunt atque provident quis repellat, ipsa esse est hic, minima aliquam id
                necessitatibus.
              </p>
              <button className="btn-red mt-4">More info</button>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img className="img-270 fit-contain" src={theme3} alt="Product" />
              <h2 className="bigger-text pt-5 pb-2">Multiphase</h2>
              <h3 className="sub-title pt-2 pb-4 h-75">Multiphase Global: the whole potential of open-innovation</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore aliquam quibusdam tenetur maiores,
                ipsum molestiae doloribus porro iure architecto, eveniet.
              </p>
              <button className="btn-yellow mt-4">More info</button>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-5 pb-lg-3">
              <img className="img-270 fit-contain" src={theme4} alt="Product" />
              <h2 className="bigger-text pt-5 pb-2">Sprint</h2>
              <h3 className="sub-title pt-2 pb-4 h-75">Sprint Ideas</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore doloribus repellendus nihil voluptatum
                dignissimos molestias corporis asperiores quas minus dicta!
              </p>
              <button className="btn-green mt-4">More info</button>
            </div>
          </div>
        </div>
      </HorizontalSection>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default InfoCompanies;
