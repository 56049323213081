import React from 'react';
import SquaredImage from '../../SquaredImage/SquaredImage';
import Spinner from '../../Spinner/spinner';

import styles from './cardInfo.module.scss';
export interface ICardInfo {
  image?: string;
  circle?: boolean;
  altText?: string;
}

const CardInfo: React.FC<ICardInfo> = (props) => {
  return (
    <div className={`${styles.cardInfoWrapper} d-flex flex-column bg-light position-relative`}>
      <div
        className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
          props.circle && 'px-5 pt-4'
        }`}
      >
        {props.image ? (
          // <img src={props.image} alt={props.altText} className={`${props.circle ?? 'circle'}`} />
          <SquaredImage image={props.image} altText={props.altText} circle={props.circle} />
        ) : (
          <div className={`${styles.squaredImageWrapper}`}>
            <div className={`${styles.imageInner}`}>
              <Spinner />
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.cardInfoContent} bg-light`}>{props.children}</div>
    </div>
  );
};

export default CardInfo;
