import React, { useState } from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';
import DropDown from '../components/Forms/DropDown/DropDown';
import Modal from '../components/Modal/Modal';
import FileUpload from '../components/Forms/FileUpload/FileUpload';
// import 'flatpickr/dist/themes/light.css';
import Flatpickr from 'react-flatpickr';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import Label from '../components/Forms/Label/Label';
import AccordionItem from '../components/Accordion/AccordionItem';

const dropDownOptions = [
  {
    label: 'Option 1',
    value: '1',
  },
  {
    label: 'Option 2',
    value: '2',
  },
  {
    label: 'Option 3',
    value: '3',
  },
  {
    label: 'Option 4',
    value: '4',
  },
  {
    label: 'Option 5',
    value: '5',
  },
  {
    label: 'Option 6',
    value: '6',
  },
  {
    label: 'Option 7',
    value: '7',
  },
  {
    label: 'Option 8',
    value: '8',
  },
  {
    label: 'Option 9',
    value: '9',
  },
  {
    label: 'Option 10',
    value: '10',
  },
  {
    label: 'Option 11',
    value: '11',
  },
  {
    label: 'Option 12',
    value: '12',
  },
];

const Forms: React.FC = () => {
  const [modalBaseVisible, setModalBaseVisible] = useState<boolean>(false);
  const [modalNCVisible, setModalNCVisible] = useState<boolean>(false);
  const [date, setDate] = useState<Date>();

  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Forms" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container-fluid page-container bg-light-gray-2">
        <div className="container pb-7">
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <label htmlFor="text">Text input</label>
            </div>
            <div className="col-12 col-md-10">
              <input type="text" name="text" placeholder="Some text" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <label htmlFor="submit">Email input</label>
            </div>
            <div className="col-12 col-md-10">
              <input type="email" name="email" placeholder="email@domain.com" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <label htmlFor="password">Password input</label>
            </div>
            <div className="col-12 col-md-10">
              <input type="password" name="password" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <label htmlFor="text">Text input disabled</label>
            </div>
            <div className="col-12 col-md-10">
              <input type="text" name="text" placeholder="Some text" disabled value="Disabled text" />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <span className="label">Radio input</span>
            </div>
            <div className="col-12 col-md-10 d-flex ">
              <div>
                <input type="radio" id="radio-left" value="left" name="radio" />
                <label htmlFor="radio-left" className="inline">
                  Radio input long label
                </label>
              </div>
              <div>
                <input type="radio" id="radio-right" value="right" name="radio" />
                <label htmlFor="radio-right" className="inline">
                  Radio input
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
              <span className="label">Checkbox input</span>
            </div>
            <div className="col-12 col-md-10">
              <input type="checkbox" name="checkbox" id="checkbox-1" />
              <label htmlFor="checkbox-1" className="inline">
                Checkbox
              </label>
            </div>
          </div>

          {/* <div className="row mb-4">
            <div className="col-2 d-flex flex-column justify-content-start">
              <span className="label">Dropdown A</span>
            </div>
            <div className="col-10">
              <div className="dropdown-row d-flex justify-content-between align-items-center">
                <select id="test" name="test" className="custom-dropdown">
                  <option>Choose </option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
              </div>
            </div>
          </div> */}

          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <span className="label">Dropdown A</span>
            </div>
            <div className="col-12 col-md-10">
              <DropDown options={dropDownOptions} />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <span className="label">Dropdown B</span>
            </div>
            <div className="col-12 col-md-10">
              <DropDown label="Variant" options={dropDownOptions} />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <span className="label">Dropdown B Thin</span>
            </div>
            <div className="col-12 col-md-10">
              <DropDown label="Variant" options={dropDownOptions} thin />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <span className="label">Textarea</span>
            </div>
            <div className="col-12 col-md-10">
              <textarea name="" cols={80} rows={10} placeholder="Write here a short text"></textarea>
            </div>
          </div>
          <div className="row mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <span className="label">Modal</span>
            </div>
            <div className="col-12 col-md-10 d-flex ">
              <button className="btn-dark-blue rounded me-2" onClick={() => setModalBaseVisible(true)}>
                Open Modal
              </button>
              <button className="btn-dark-blue rounded" onClick={() => setModalNCVisible(true)}>
                Open Modal No Cancel
              </button>
            </div>
          </div>
          <div className="row mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <Label
                mandatory
                id="prova-1"
                tooltip=" Describe your design in a deep way.<br/> Tell us the reason of you choice, motivation and everything you think coul be useful to understanf your project"
              >
                Tooltip
              </Label>
            </div>
            <div className="col-12 col-md-10 d-flex ">Just an example</div>
          </div>
          <div className="row mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <Label id="prova-2" tooltip="A bit less text for this one">
                Tooltip less text
              </Label>
            </div>
            <div className="col-12 col-md-10 d-flex ">Just an example</div>
          </div>

          <div className="row mb-4 mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <Label id="dandd">Drag & drop upload</Label>
            </div>
            <div className="col-12 col-md-10">
              <FileUpload />
            </div>
          </div>

          <div className="row mb-4 mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <Label id="dandd">Accordion</Label>
            </div>
            <div className="col-12 col-md-10">
              <AccordionItem title="Accordion item">
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
              </AccordionItem>
              <AccordionItem title="Another accordion item">
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
                <h2>A title</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque nobis blanditiis ex consequatur
                  voluptatem nam, ipsam reprehenderit rerum suscipit praesentium asperiores, consectetur aspernatur a
                  eveniet, quasi provident quos doloribus nisi.
                </p>
              </AccordionItem>
            </div>
          </div>
          <div className="row mb-4 mt-7">
            <div className="col-12 col-md-2 d-flex flex-column justify-content-start">
              <Label id="dandd">Datepicker</Label>
            </div>
            <div className="col-12 col-md-10">
              <Flatpickr
                value={date}
                options={{ dateFormat: 'd-m-Y' }}
                onChange={([date]) => {
                  setDate(date);
                }}
              />
            </div>
          </div>
        </div>
      </main>

      <Modal
        title="Test modal"
        confirmLabel="Confirm"
        visible={modalBaseVisible}
        onConfirm={() => setModalBaseVisible(false)}
        onCancel={() => setModalBaseVisible(false)}
        buttonColor="green"
      >
        <div className="row justify-content-center text-center py-3">
          <div className="col-8">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis officiis quaerat deleniti. Error, culpa
              omnis cupiditate, id officia doloribus praesentium nemo quisquam dolore aut, commodi quibusdam quod ipsam
              exercitationem esse.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        title="Test modal no cancel"
        confirmLabel="Confirm"
        visible={modalNCVisible}
        onConfirm={() => setModalNCVisible(false)}
      >
        <div className="row justify-content-center text-center py-3">
          <div className="col-8">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis officiis quaerat deleniti. Error, culpa
              omnis cupiditate, id officia doloribus praesentium nemo quisquam dolore aut, commodi quibusdam quod ipsam
              exercitationem esse.
            </p>
          </div>
        </div>
      </Modal>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Forms;
