import React from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faPencil, faTrophy, faIndustry } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRow.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';

interface IContestRow extends ICardInfo {
  category: string;
  contestTitle: string;
  startDate: string;
  clientName: string;
  entries: number;
  award: number;
  galleryImages: string[];

  status: ContestStatus;
  progress?: number;
}

const ContestRowGallery = (props: { items: string[] }) => {
  let maxItems = props.items.slice(0, 5);
  let missingItems = 5 - maxItems.length;

  for (let i = 0; i < missingItems; i++) {
    maxItems.push('');
  }

  return (
    <>
      {maxItems.map((item, index) => (
        <div className={`${styles.contestRowGalleryItem} ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}>
          {item ? <img src={item} alt="Gallery Item Cover" /> : <Spinner size={5} />}
        </div>
      ))}
    </>
  );
};

const ContestRow: React.FC<IContestRow> = (props) => {
  return (
    <div className={`${styles.contestRowWrapper} col-12 mb-5 position-relative`}>
      <MultiActionButtonsWrapper position="top-right" rounded>
        <ActionButton label="Private gallery" icon="private" />
        <ActionButton label="Access code" icon="accesscode" />
        <ActionButton label="Accept nda" icon="acceptnda" />
      </MultiActionButtonsWrapper>
      <div className="row bg-light">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${
              props.image ? styles.contestRowImage : styles.contestRowImagePlaceholder
            } bg-light-gray-2 flex-shrink-0 position-relative`}
          >
            {props.image ? (
              <img src={props.image} alt="Contest Cover" />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
            <div className={styles.statusBar}>
              <StatusBar status={props.status} progress={props.progress} />
            </div>
            <ActionButton icon="addFavorite" position="top-right" action={() => alert('Added as favorite')} />
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12 col-lg-5 d-flex flex-column justify-content-center ps-5 mt-4 mt-lg-0">
                <h2 className="text-xl fw-bold color-blue py-0 my-0">{props.contestTitle}</h2>
                <div className="text">{props.category}</div>
              </div>
              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.clientName}</div>
                </div>
              </div>
              {/* Project Stats */}
              <div className="col-6 col-lg-3 d-flex flex-column justify-content-center px-2">
                <div className="d-flex justify-content-start mb-2">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-success">Ends: {props.startDate}</div>
                </div>
                <div className="d-flex justify-content-start mb-2">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">{props.entries} entries</div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">€ {props.award} award</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div
          className={`${styles.contestRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}
        >
          <ContestRowGallery items={props.galleryImages} />
        </div>
      </div>
    </div>
  );
};

export default ContestRow;
