import React from 'react';
import styles from './TimeLine.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIndustry, faUsers, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import { IMultiTimeLine, IPhase } from './timelineInterfaces';
import TLHLP from './timeline.helper';

import Timer from './components/Timer';
import ProgressSection from './components/ProgressSection';

interface InternalPhase extends IPhase {
  isActive: boolean;
}

const TimeLinePhase: React.FC<InternalPhase> = (props) => {
  const uploadProgress: number = TLHLP.getProgress(props.upload);
  const communityProgress: number | undefined = props.community ? TLHLP.getProgress(props.community) : undefined;
  const clientProgress: number = TLHLP.getProgress(props.client);

  let isClosed: boolean = uploadProgress + (communityProgress ?? 100) + clientProgress === 300;

  return (
    <>
      <div className={`flex-grow-1 ${styles.progressWrapper} ${styles.upload} ${isClosed && styles.closed}`}>
        <ProgressSection
          progress={uploadProgress}
          label="Upload Phase"
          icon={faCloudArrowUp as IconProp}
          iconLabel="concept design"
          daysLeft={TLHLP.getDaysLeft(props.upload)}
          active={TLHLP.isUploadActive(props) && props.isActive}
        />
      </div>

      {communityProgress !== undefined && (
        <div className={`flex-shrink-1 ${styles.progressWrapper} ${styles.community} ${isClosed && styles.closed}`}>
          <ProgressSection
            progress={communityProgress}
            label="Vote"
            icon={faUsers as IconProp}
            daysLeft={props.community ? TLHLP.getDaysLeft(props.community) : 0}
            active={TLHLP.isCommunityactive(props) && props.isActive}
          />
        </div>
      )}
      <div className={`flex-shrink-1 ${styles.progressWrapper} ${styles.client} ${isClosed && styles.closed}`}>
        <ProgressSection
          progress={clientProgress}
          label="Client Vote"
          icon={faIndustry as IconProp}
          daysLeft={TLHLP.getDaysLeft(props.client)}
          active={TLHLP.isClientActive(props) && props.isActive}
        />
      </div>
    </>
  );
};

const TimeLineMultiPhase: React.FC<IMultiTimeLine> = (props) => {
  return (
    <div className={`row ${styles.timelineContainer}`}>
      <div className={`px-0 d-flex ${styles.timelineWrapper} ${TLHLP.isClosed(props.phases) && styles.closed} `}>
        <Timer leftDays={TLHLP.getLeftDays(props.phases)} />

        {props.phases.map((phase, index) => {
          let isPhaseActive =
            TLHLP.getPassedDays(props.phases) >= TLHLP.getPhaseStartDay(props.phases, index) &&
            TLHLP.getPassedDays(props.phases) <= TLHLP.getPhaseEndDay(props.phases, index);

          return (
            <TimeLinePhase
              upload={phase.upload}
              community={phase.community}
              client={phase.client}
              isActive={isPhaseActive}
            />
          );
        })}
        <div className={`col flex-shrink-1 ${styles.progressWrapper} ${styles.endSection} m-0 p-0`}>
          {TLHLP.isClosed(props.phases) && (
            <ProgressSection progress={100} label="Closed" closed={true} daysLeft={0} active={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeLineMultiPhase;
