import React from 'react';
import { format } from 'fecha';

import UserPic from '../Community/UserPic/UserPic';
import { IMessage } from './Conversation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentDots, faFlag, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './Conversation.module.scss';

interface MessageComponent extends IMessage {
  canReply?: boolean;
}

const handleDelete = () => {
  alert('TODO Delete message');
};

const handleReply = () => {
  alert('TODO reply to message');
};
const handleFlag = () => {
  alert('TODO Flag message');
};

const Message: React.FC<MessageComponent> = (props) => {
  return (
    <div className={`row ${styles.messageWrapper} mb-5`}>
      <div className="col-12">
        <div className={`row d-flex flex-row justify-content-start`}>
          <div className={`col ${styles.profilePic}`}>
            <UserPic image={props.author.profilePic} />
          </div>
          <div className="col">
            <div className="row">
              <div className="col-12 mb-3">
                <span className="text-xl fw-bold color-blue me-3">{props.author.username}</span>
                <span className="text color-dark fst-italic">
                  {format(new Date(props.date), 'dd MMM YYYY [at] HH:mm')}
                </span>
              </div>
              <div className="col-12">{props.text}</div>
              <div className="col-12 mt-3">
                {props.canReply && (
                  <button className={`text ${styles.actionButton}`} onClick={() => handleReply()}>
                    <FontAwesomeIcon icon={faCommentDots as IconProp} className="text color-blue me-2" />
                    Reply
                  </button>
                )}
                <button className={`text ${styles.actionButton}`} onClick={() => handleFlag()}>
                  <FontAwesomeIcon icon={faFlag as IconProp} className="text color-yellow me-2" />
                  Flag
                </button>
                <button className={`text ${styles.actionButton}`} onClick={() => handleDelete()}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className="text color-red me-2" />
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {props.replies && (
          <div className="row">
            <div className="col-1"></div>
            <div className="col-11 pt-5">
              {props.replies?.map((reply) => (
                <Message {...reply} canReply={false} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
