export const forCreativesPages: INavLink[] = [
  {
    text: "Panoramic",
    path: "/creatives",
  },
  {
    text: "Additional info",
    path: "/creatives-additional-info",
  },
  {
    text: "Dream Team",
    path: "/creatives-dream-team",
  },
  {
    text: "Community",
    path: "/community",
  },
  {
    text: "Contest",
    path: "/contest",
  },    
];