import React from 'react';
import GridLoader from 'react-spinners/GridLoader';

interface ISpinner {
  size?: number;
  loading?: boolean;
}

const Spinner: React.FC<ISpinner> = (props) => {
  return <GridLoader loading={props.loading ?? true} size={props.size ?? 15} color="#c7c7c7" />;
};

export default Spinner;
