import React from 'react';
import styles from './StatusBar.module.scss';

interface IStatusBar {
  status: ContestStatus;
  progress?: number;
}

const StatusBar: React.FC<IStatusBar> = (props) => {
  let backgroundStyles: string;

  switch (props.status) {
    case 'standby':
      backgroundStyles = styles.standby;
      break;
    case 'ongoing':
      backgroundStyles = styles.ongoing;
      break;
    case 'voting':
      backgroundStyles = styles.voting;
      break;
    case 'closed':
      backgroundStyles = styles.closed;
      break;
    default:
      backgroundStyles = styles.standby;
      break;
  }

  return (
    <div className={`${styles.statusBar} ${backgroundStyles}`}>
      {<div className={`${styles.progressBar}`} style={{ width: (props.progress ?? '100') + '%' }}></div>}
    </div>
  );
};

export default StatusBar;
