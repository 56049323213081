import React from 'react';

//Header Data
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faFacebook, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';

//Assets
import creative from '../assets/creative_placeholder.png';
import footerImg from '../assets/footer_placeholder_transparent.png';

//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

const SignUp: React.FC = () => {
  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={creative} pageTitle="Home" />
      <main className="container-fluid py-5 bg-light-gray">
        <div className="row text-center justify-content-center">
          <div className="col-12 col-md-3 col-lg-2">
            <h1 className="sub-title color-dark mb-5">Connect with</h1>
            <button className="btn-social__twitter full rounded mb-3">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faTwitter as IconProp} className="text color-light" />
              </div>
              twitter
            </button>
            <button className="btn-social__facebook full rounded mb-3">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
              </div>
              facebook
            </button>
            <button className="btn-social__linkedin full rounded mb-3">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
              </div>
              linkedin
            </button>
            <button className="btn-social__google full rounded">
              <div className="btn-icon">
                <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
              </div>
              google
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 col-lg-2">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="sub-title color-dark my-5">or sign up</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input type="username" name="username" placeholder="username" className="mb-5" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input type="email" name="email" placeholder="email" className="mb-5" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input type="password" name="password" placeholder="password" className="mb-5" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input type="password" name="password" placeholder="repeat password" className="mb-5" />
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex mb-5">
                <div>
                  <input type="checkbox" name="checkbox" id="checkbox-1" className="flex-shrink-none" />
                </div>
                <div>
                  <label htmlFor="checkbox-1" className="d-block text-start ps-3 text">
                    I’m over 18 and agree to the <a href="#">Privacy Policy</a> & <a href="#">User Agreement</a>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex">
                <div>
                  <input type="checkbox" name="checkbox" id="checkbox-1" className="flex-shrink-none" />
                </div>
                <div>
                  <label htmlFor="checkbox-1" className="d-block text-start ps-3 text">
                    I want to receive the newsletter and agree to the <a href="#">Privacy policy Newsletter</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-3 col-lg-2 my-5">
            <button className="btn-dark-blue full rounded 3">Signup</button>
          </div>
        </div>
      </main>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default SignUp;
