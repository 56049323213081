import React, { useState } from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';
import DropDown from '../components/Forms/DropDown/DropDown';
import Modal from '../components/Modal/Modal';
import FileUpload from '../components/Forms/FileUpload/FileUpload';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import Label from '../components/Forms/Label/Label';
import AccordionItem from '../components/Accordion/AccordionItem';

import TimeLine from '../components/TimeLine/TimeLine';
import TimeLineMultiPhase from '../components/TimeLine/TimeLineMultiphase';

let phases = [
  {
    upload: {
      total: 50,
      passed: 50,
    },
    community: {
      total: 25,
      passed: 10,
    },
    client: {
      total: 25,
      passed: 0,
    },
  },
  {
    upload: {
      total: 50,
      passed: 0,
    },
    community: {
      total: 25,
      passed: 0,
    },
    client: {
      total: 25,
      passed: 0,
    },
  },
  {
    upload: {
      total: 50,
      passed: 0,
    },
    community: {
      total: 25,
      passed: 0,
    },
    client: {
      total: 25,
      passed: 0,
    },
  },
];
let tl0 = {
  upload: {
    total: 50,
    passed: 0,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

let tl1 = {
  upload: {
    total: 50,
    passed: 10,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

let tl2 = {
  upload: {
    total: 50,
    passed: 30,
  },
  community: {
    total: 25,
    passed: 0,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

let tl3 = {
  upload: {
    total: 50,
    passed: 50,
  },
  client: {
    total: 25,
    passed: 15,
  },
};

let tl4 = {
  upload: {
    total: 50,
    passed: 50,
  },
  community: {
    total: 25,
    passed: 10,
  },
  client: {
    total: 25,
    passed: 0,
  },
};

let tl5 = {
  upload: {
    total: 50,
    passed: 50,
  },
  client: {
    total: 25,
    passed: 25,
  },
};

const Components: React.FC = () => {
  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Forms" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container-fluid page-container bg-light">
        <div className="container pb-7">
          <div className="row">
            <div className="col-12">
              <h2 className="sub-title color-dark">Tags</h2>
            </div>
            <div className="col-9 border-end">
              <button className="btn-tag mb-4">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button>
            </div>
            <div className="col-3">
              <button className="btn-tag bg-light mb-4">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="sub-title color-dark">Timeline</h2>
            </div>
            <div className="col-12">
              <TimeLine progress={tl0} />
              <TimeLine progress={tl1} />
              <TimeLine progress={tl2} />
              <TimeLine progress={tl3} />
              <TimeLine progress={tl4} />
              <TimeLine progress={tl5} />
              <TimeLineMultiPhase phases={phases} />
            </div>
          </div>
        </div>
      </main>

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Components;
