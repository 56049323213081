import React from 'react';
import styles from './Modal.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { propTypes } from 'react-bootstrap/esm/Image';

interface IModal {
  title?: string;
  confirmLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  buttonColor?: 'blue' | 'green' | 'yellow' | 'red';
  visible: boolean;
}

const Modal: React.FC<IModal> = (props) => {
  const handleOnConfirm = () => {
    props.onConfirm && props.onConfirm();
    console.log('confirm');
  };

  const handleOnCancel = () => {
    props.onCancel && props.onCancel();
    console.log('cancel');
  };

  const getButtonStyle = (color: string) => {
    switch (color) {
      case 'blue':
        return styles.confirmBlue;
        break;
      case 'green':
        return styles.confirmGreen;
        break;
      case 'yellow':
        return styles.confirmYellow;
        break;
      case 'red':
        return styles.confirmRed;
        break;
      default:
        return styles.confirmDefault;
        break;
    }
  };

  return (
    <>
      {true && (
        <section className={`${styles.modal__overlay} ${props.visible && styles.visible}`}>
          <div className={`${styles.modal__content_wrapper}`}>
            <div className="container">
              <div className="row">
                {props.title && <div className="col-12 sub-title color-dark text-center pb-4">{props.title}</div>}
                <div className="col-12 px-0">{props.children}</div>
                <div className="col-12">
                  <div className="row mt-4">
                    {props.confirmLabel && (
                      <div className={`${props.onCancel ? 'col-6' : 'col-12'} d-flex justify-content-center`}>
                        <button
                          className={`btn-dark-blue ${getButtonStyle(props.buttonColor ?? '')} rounded`}
                          onClick={() => handleOnConfirm()}
                        >
                          {props.confirmLabel}
                        </button>
                      </div>
                    )}
                    {props.onCancel && (
                      <div
                        className="col-6 d-flex justify-content-center align-items-center cursor-pointer"
                        onClick={() => handleOnCancel()}
                      >
                        <div className="pe-4">Cancel</div>
                        <button className="btn-icon squared bg-red color-light">
                          <FontAwesomeIcon icon={faXmark as IconProp} className="text" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Modal;
