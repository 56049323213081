import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PageNavigation.module.scss';

interface IPageNavigation {
  links: INavLink[];
  bgColor?: string;
}

const PageNav = ({ links }: { links: INavLink[] }) => {
  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) => (
        <Link
          key={'pageLink_' + index}
          className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTab} ${
            index === 0 && styles.activeTab
          }`}
          to={navLink.path}
        >
          {navLink.text}
        </Link>
      ))}
    </>
  );
};

const PageNavigation: React.FC<IPageNavigation> = (props) => {
  return (
    <div className={`${styles.navigationWrapper} container-fluid px-0`} style={{ backgroundColor: props.bgColor }}>
      <div className="container-md">
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapper}`}>
            <PageNav links={props.links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNavigation;
