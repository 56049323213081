import React from 'react';
import styles from './Timer.module.scss';

interface ITimer {
  leftDays: number;
}

// https://swiperjs.com/react
/**
 * Must have at least two children
 * @param timeInterval auto swipte interval in ms
 * @returns
 */
const Timer: React.FC<ITimer> = (props) => {
  return (
    <div
      className={`${styles.mainCountDown} col-3 flex-shrink-1 bg-light-gray-3 color-light fw-bold d-flex align-items-center text-center`}
    >
      <div className="px-3">
        <div className="text-xl fw-bold">{props.leftDays}</div>
        <div>days</div>
      </div>
      <div className="d-none d-lg-flex px-3 px-md-0 px-lg-3 ps-5">
        <div className="px-2">
          <div className="text-xl fw-bold">21</div>
          <div>hrs</div>
        </div>
        <div className="separator">:</div>
        <div className="px-2">
          <div className="text-xl fw-bold">33</div>
          <div>min</div>
        </div>
        <div className="separator">:</div>
        <div className="px-2">
          <div className="text-xl fw-bold">16</div>
          <div>sec</div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
