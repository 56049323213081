import React from 'react';
import CardSmall from '../components/CardSmall/CardSmall';
import CardLarge from '../components/CardLarge/CardLarge';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/libraryPages';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

const Cards: React.FC = () => {
  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={headerimg} pageTitle="Cards" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className="container page-container">
        <div className="row">
          <div className="col-12">
            <h1 className="light-title">270x270</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-wrap">
            <div className="p-2">
              <p>Top and bottom text, no animation</p>
              <CardSmall
                topText="Small Card"
                bottomText="Card size 270x270"
                backGround="https://source.unsplash.com/collection/928423/480x480"
                showContent={true}
              ></CardSmall>
            </div>
            <div className="p-2">
              <p>Top text only, clickable, no animation</p>
              <CardSmall
                topText="Click me"
                backGround="https://source.unsplash.com/collection/928423/480x480"
                showContent={true}
                onClick={() => alert('clicked')}
              ></CardSmall>
            </div>
            <div className="p-2">
              <p>Basic, animated</p>
              <CardSmall
                topText="Small Card"
                bottomText="Card size 270x270"
                backGround="https://source.unsplash.com/collection/928423/480x480"
              ></CardSmall>
            </div>
            <div className="p-2">
              <p>With children content, animated</p>
              <CardSmall backGround="https://source.unsplash.com/collection/928423/480x480">
                <div className="color-light text-xxl">Children content</div>
                <div className="color-light text">Children content too</div>
              </CardSmall>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="light-title">370x370</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-wrap">
            <div className="p-2">
              <p>Top and bottom text, no animation</p>
              <CardLarge
                topText="Small Card"
                bottomText="Card size 370x370"
                backGround="https://source.unsplash.com/collection/928423/480x480"
                showContent={true}
              ></CardLarge>
            </div>
            <div className="p-2">
              <p>Top text only, clickable, no animation</p>
              <CardLarge
                topText="Click me"
                backGround="https://source.unsplash.com/collection/928423/480x480"
                showContent={true}
                onClick={() => alert('clicked')}
              ></CardLarge>
            </div>
            <div className="p-2">
              <p>With children content, animated</p>
              <CardLarge backGround="https://source.unsplash.com/collection/928423/480x480">
                <div className="color-light text-xxl">Children content</div>
                <div className="color-light text">Children content too</div>
              </CardLarge>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Cards;
