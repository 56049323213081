import { IPhase, ITimeSection } from './timelineInterfaces';

class TimelineHelper {
  getProgress(status: ITimeSection) {
    return (status.passed / status.total) * 100;
  }

  getDaysLeft(status: ITimeSection) {
    return status.total - status.passed;
  }

  //Verifico quale sezione è attiva per mostrare o nascondere la label
  //TODO rifattorizzare in modo più performante e generico
  isUploadActive(phase: IPhase) {
    return phase.upload.passed <= phase.upload.total;
  }

  isCommunityactive(phase: IPhase) {
    return (
      phase.community !== undefined &&
      phase.upload.passed === phase.upload.total &&
      phase.community.passed <= phase.community.total
    );
  }

  isClientActive(phase: IPhase) {
    return (
      phase.upload.passed === phase.upload.total &&
      (phase.community !== undefined ? phase.community.passed === phase.community.total : true)
    );
  }

  // Multiphase =============================================

  getTotalDays(phases: IPhase[]) {
    let totalDays = 0;
    phases.forEach((phase) => {
      totalDays += phase.upload.total + (phase.community?.total ?? 0) + phase.client.total;
    });
    return totalDays;
  }

  getPassedDays(phases: IPhase[]) {
    let totalPassed = 0;
    phases.forEach((phase) => {
      totalPassed += phase.upload.passed + (phase.community?.passed ?? 0) + phase.client.passed;
    });

    return totalPassed;
  }

  getPhaseStartDay(phases: IPhase[], index: number) {
    let startsAtDay = 0;

    if (index === 0) {
      return 0;
    }

    for (let i = 0; i < index; i++) {
      let communityTotal = phases[i].community !== undefined ? phases[i].community?.total : 0;

      startsAtDay += phases[i].upload.total;
      startsAtDay += communityTotal ?? 0;
      startsAtDay += phases[i].client.total;
    }

    return startsAtDay;
  }

  getPhaseEndDay(phases: IPhase[], index: number) {
    let endsAtDay = 0;

    for (let i = 0; i <= index; i++) {
      let communityTotal = phases[i].community !== undefined ? phases[i].community?.total : 0;

      endsAtDay += phases[i].upload.total;
      endsAtDay += communityTotal ?? 0;
      endsAtDay += phases[i].client.total;
    }

    return endsAtDay;
  }

  getLeftDays(phases: IPhase[]) {
    return this.getTotalDays(phases) - this.getPassedDays(phases);
  }

  isClosed(phases: IPhase[]) {
    return this.getLeftDays(phases) === 0 ? true : false;
  }
}

export default new TimelineHelper();
