export const forCompanyPages: INavLink[] = [
    {
      text: "Panoramic",
      path: "/companies",
    },
    {
      text: "On the market",
      path: "/market",
    },
    {
      text: "Case studies",
      path: "/case-studies",
    },
    {
      text: "Launch a contest",
      path: "/contest",
    },
  ];