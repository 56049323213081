export enum ROUTEPATHS {
  HOME = '/',
  SIGNUP = '/signup',
  COMMUNITY = 'community',

  CONTEST = 'contest',
  CONTEST_BRIEF = 'brief',

  INFO_COMPANIES = 'companies',
  INFO_CREATIVES = 'creatives',
  INFO_CREATIVES_ADDITIONAL = 'creatives-additional-info',
  INFO_CREATIVES_DREAMTEAM = 'creatives-dream-team',
  INFO_CREATIVES_ABOUT = 'about-us',
  INFO_CREATIVES_PARTNERS = 'partners',

  COMPANIES_MARKET = 'market',
  COMPANIES_CASE_STUDIES = 'case-studies',

  COMMUNITY_PROFILE = 'profile',
  COMMUNITY_PROFILE_EDITABLE = 'profile-editable',
  COMMUNITY_PORTFOLIO = 'portfolio',
  COMMUNITY_DESIGN = 'design',

  LIBRARY = 'library',
  TYPOGRAPHY = 'typography',
  IMAGES = 'images',
  BUTTONS = 'buttons',
  CARDS = 'cards',
  FORMS = 'forms',
  COMPONENTS = 'components',
}
