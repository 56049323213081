import React from 'react';
import { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faUser, faTrophy, faHeart } from '@fortawesome/free-solid-svg-icons';

import styles from './ProjectRow.module.scss';

interface IProjectRow extends ICardInfo {
  title: string;
  category: string;
  contestTitle: string;
  startDate: string;
  name: string;
  votes: number;
  galleryImages: string[];
}

const ProjectRowGallery = (props: { items: string[] }) => {
  let maxItems = props.items.slice(0, 5);
  let missingItems = 5 - maxItems.length;

  for (let i = 0; i < missingItems; i++) {
    maxItems.push('');
  }

  return (
    <>
      {maxItems.map((item, index) => (
        <div className={`${styles.projectRowGalleryItem} ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}>
          {item ? (
            <img src={item} alt="Gallery Item Cover" />
          ) : // <FontAwesomeIcon icon={faCircle as IconProp} className="color-light" />
          null}
        </div>
      ))}
    </>
  );
};

const ProjectRow: React.FC<IProjectRow> = (props) => {
  return (
    <div className={`${styles.projectRowWrapper} col-12 mb-5`}>
      <div className="row bg-light">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div
            className={`${
              props.image ? styles.projectRowImage : styles.projectRowImagePlaceholder
            } bg-light-gray flex-shrink-0`}
          >
            {props.image ? (
              <img src={props.image} alt="Project Cover" />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12 col-lg-4 d-flex flex-column justify-content-center ps-5 mt-4 mt-lg-0">
                <h2 className="text-xl fw-bold color-blue">{props.title}</h2>
              </div>
              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.name}</div>
                </div>
              </div>
              {/* Project Stats */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center px-2">
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start">Posted: {props.startDate}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.contestTitle}</div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-blue">{props.votes} votes</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div
          className={`${styles.projectRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}
        >
          <ProjectRowGallery items={props.galleryImages} />
        </div>
      </div>
    </div>
  );
};

export default ProjectRow;
