import React from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import ProfileHeader from '../../components/Profile/ProfileHeader/ProfileHeader';

import ContestStats from '../../components/Community/ContestStats/ContestStats';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faCircle, faMapMarkerAlt, faFlask } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { topNavPages } from '../../mockdata/navPages';
import PageNavigation from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './Profile.module.scss';
import ProfilePic from '../../components/Profile/ProfilePic/ProfilePic';

const Profile: React.FC = () => {
  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Profile"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className={`${styles.profileWrapper} container pt-5 mb-5`}>
        <div className="row">
          <div className="col-12">
            <ProfileHeader bgImage="https://source.unsplash.com/pEgsWN0kwbQ/1200x600" />
          </div>
        </div>
        <div className="row">
          <div className={`col-12 col-md-3 ${styles.profilePic}`}>
            <ProfilePic image="https://source.unsplash.com/3TLl_97HNJo/480x480">
              <div className={`${styles.badges}`}>
                <div className={`${styles.badge} bg-red color-light`}>
                  <FontAwesomeIcon icon={faFlask as IconProp} />
                </div>
                <div className={`${styles.badge} bg-green color-light`}>
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
                </div>
              </div>
            </ProfilePic>
          </div>
          <div className="col-12 col-md-3 pt-5">
            <div className="info-main mb-7">
              <h1 className="sub-title color-blue">Karen White</h1>
              <div className="text color-dark">Industrial designer</div>
            </div>

            <div className="info">
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">San Francisco, USA</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text">since 04 Mar 19</div>
              </div>
            </div>
            <div className="badges">
              <div className="d-flex justify-content-start">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCircle as IconProp} className=" text color-green me-3" />
                </div>
                <div className="text color-green">Dream Team user</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-5">
            <ContestStats
              entries={34}
              inspirations={12}
              awards={7500}
              contests={3}
              reputation={135}
              favorites={267}
              votes={240}
              comments={123}
            />
          </div>
        </div>
        <div className="row">
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-3">
            <div className="buttons">
              <button className="btn-blue rounded full mt-7 mb-3">Add to favorite</button>
              <button className="btn-blue rounded full mb-3">Send message</button>
              <button className="btn-red rounded big full mb-3">Block</button>
            </div>
            <div className="tags mt-7 d-flex flex-wrap">
              <button className="btn-tag mb-4">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-9 pt-5">
            <div className="row">
              <div className="col-12 ps-5">
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Username</div>
                  <div className="col-12 col-md-10">KarenWhite</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Name</div>
                  <div className="col-12 col-md-10">Karen JuliaWhite</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Biography</div>
                  <div className="col-12 col-md-10">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt? Lorem ipsum dolor sit amet consectetur adipisicing elit. In omnis enim
                      molestias voluptate recusandae minus cupiditate expedita eveniet officiis quo molestiae atque,
                      numquam pariatur nihil repellendus, nesciunt aliquam quam deserunt.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt?
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Who are you</div>
                  <div className="col-12 col-md-10">A designer</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Gender</div>
                  <div className="col-12 col-md-10">She/Her</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Website</div>
                  <div className="col-12 col-md-10">
                    <a href="#">http://www.thisisatestlink.com</a>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Social Network</div>
                  <div className="col-12 col-md-10">
                    <a href="#">http://www.thisisatestlink.com</a>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Profile;
