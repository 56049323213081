import React from 'react';
import CardInfo, { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faUser,
  faTrophy,
  faHeart,
  faMapMarkerAlt,
  faDollarSign,
  faPencil,
  faFlask,
  faAward,
} from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerCard.module.scss';

interface IDesignerCard extends ICardInfo {
  circle?: boolean;
  contest?: number;
  reputation?: number;
  name: string;
  role: string;
  location: string;

  userType: string;
  startDate: string;
  awarded: number;
  entries: number;
}

const DesignerCard: React.FC<IDesignerCard> = (props) => {
  return (
    <div className="col-12 col-md-6 col-lg-3 mb-4">
      <CardInfo image={props.image} circle={props.circle}>
        <div className="text-center">
          <ActionButton
            label="Save as favorite"
            icon="addFavorite"
            position="top-right"
            action={() => alert('Added as favorite')}
          />
          <div className={`${styles.badges} d-flex justify-content-end`}>
            <div className={`${styles.badge} bg-red color-light`}>
              <FontAwesomeIcon icon={faFlask as IconProp} />
            </div>
            <div className={`${styles.badge} bg-blue color-light`}>
              <FontAwesomeIcon icon={faAward as IconProp} />
            </div>
          </div>
          <h2 className="text-xl fw-bold color-blue mt-4 mb-4 px-3">{props.name}</h2>
          <div className="text mb-5">{props.role}</div>
          <div className="d-flex flex-column align-items-center">
            <div className="info" style={{ maxWidth: '65%' }}>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">{props.location}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">{props.userType}</div>
              </div>
              <div className="d-flex justify-content-start mb-5">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start">Since: {props.startDate}</div>
              </div>
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-blue">
                  <span className="fw-bold">{props.entries}</span> entries
                </div>
              </div>
              {props.reputation && (
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-dark">
                    <span className="fw-bold">{props.reputation}</span> reputation
                  </div>
                </div>
              )}
              {props.contest && (
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start color-dark">
                    <span className="fw-bold">{props.contest}</span> contest
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-start mb-3">
                <div className="d-flex flex-column justify-content-center">
                  <FontAwesomeIcon icon={faDollarSign as IconProp} className=" text color-blue me-3" />
                </div>
                <div className="text text-start color-dark">
                  <span className="fw-bold">€ {props.awarded}</span> awarded
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardInfo>
    </div>
  );
};

export default DesignerCard;
