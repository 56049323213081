import React from 'react';
import styles from './HeroSection.module.scss';
import { hexToRGB } from '../../helpers/utils';

interface IHeroSection {
  bgImage?: string;
  bgVideo?: string;
  bgColor: string;
  alpha?: number;
  minHeight?: string;
}

/**
 * Hero image
 * @param {string} bgImage Background image
 * @param {string} bgVideo Background video
 * @param {string} bgColor Background color and overlay color in HEX
 * @param {string} alpha Alpha da 0 a 1, riferito all'overlay sopra l'immagine
 * @returns
 */
const HeroSection: React.FC<IHeroSection> = (props) => {
  let c = hexToRGB(props.bgColor);

  //Dynamic background styles
  let imageBackgroundStyles = {
    minHeight: `${props.minHeight ?? '50vh'}`,
    backgroundImage: `linear-gradient(rgba(${c[0]},${c[1]},${c[2]}, ${props.alpha ?? 1}), rgba(${c[0]},${c[1]},${
      c[2]
    }, ${props.alpha ?? 1})), url(${props.bgImage})`,
    backgroundColor: `rgba(${c[0]},${c[1]},${c[2]}, 1)`,
  };

  let videoBackgroundStyles = {
    minHeight: `${props.minHeight ?? '50vh'}`,
  };

  //https://blog.hubspot.com/website/video-background-css
  const VideoBackground = () => {
    return (
      <video
        className={`${styles.videoBackground}`}
        style={videoBackgroundStyles}
        id="background-video"
        autoPlay
        loop
        muted
        poster={props.bgImage}
      >
        <source src={props.bgVideo} type="video/mp4" />
      </video>
    );
  };

  return (
    <div
      className={`${styles.heroWrapper} container-fluid d-flex flex-row justify-content-center align-items-center`}
      style={props.bgVideo ? videoBackgroundStyles : imageBackgroundStyles}
    >
      {props.bgVideo && <VideoBackground />}
      <div className={`container ${styles.contentContainer}`}>
        <div className="row justify-content-center">
          <div className="col-8 text-center">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
