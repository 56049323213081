import React, { useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import UserPic from '../../components/Community/UserPic/UserPic';
import Gallery from '../../components/Gallery/Gallery';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faFlask, faUser, faTrophy, faHeart } from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { topNavPages } from '../../mockdata/navPages';
import PageNavigation from '../../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';

//Styles
import styles from './Design.module.scss';
import Conversation, { IMessage } from '../../components/Conversation/Conversation';

const images = [
  {
    original: 'https://picsum.photos/id/1018/960/720/',
    thumbnail: 'https://picsum.photos/id/1018/250/200/',
  },
  {
    original: 'https://picsum.photos/id/1015/960/720/',
    thumbnail: 'https://picsum.photos/id/1015/250/200/',
  },
  {
    original: 'https://picsum.photos/id/1019/960/720/',
    thumbnail: 'https://picsum.photos/id/1019/250/200/',
  },
  {
    original: 'https://picsum.photos/id/1043/960/720/',
    thumbnail: 'https://picsum.photos/id/1043/250/200/',
  },
  {
    original: 'https://picsum.photos/id/1050/960/720/',
    thumbnail: 'https://picsum.photos/id/1050/250/200/',
  },
];

const mockAuthor = {
  username: 'Anna Salting',
  profilePic: 'https://source.unsplash.com/rDEOVtE7vOs/480x480',
  email: 'anna.salting@mailtesst.com',
};

const mockAuthor2 = {
  username: 'Calvin Merkaat',
  profilePic: 'https://source.unsplash.com/XHVpWcr5grQ/480x480',
  email: 'calvin.merkaat@mailtesst.com',
};
const mockAuthor3 = {
  username: 'Garabed Oller',
  profilePic: 'https://source.unsplash.com/EPi3TRQc5Z0/480x480',
  email: 'garabed.oller@mailtesst.com',
};

const mockAuthor4 = {
  username: 'Aashvi Kornell',
  profilePic: 'https://source.unsplash.com/_H6wpor9mjs/480x480',
  email: 'aashvi.kornell@mailtesst.com',
};

const mockReplies = [
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor2,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  },
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor3,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  },
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor2,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  },
];

const mockMessages: IMessage[] = [
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor4,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
    replies: mockReplies,
  },
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
  },
  {
    id: '95a611b7-bdf5-454a-ba76-1ad656d9d6bd',
    author: mockAuthor2,
    date: new Date().toString(),
    text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.',
    replies: mockReplies,
  },
];

const Design: React.FC = () => {
  const [galleryZoom, setGalleryZoom] = useState<boolean>(false);

  const handleGalleryZoom = (zoom: boolean) => {
    setGalleryZoom(zoom);
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={topNavPages}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="A beautiful but long title on three rows"
      />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <main className={`${styles.designWrapper} container pt-5 mb-5`}>
        <div className="row gx-5">
          {/* Col left ============================================ */}
          <div className={`col-12 ${galleryZoom ? 'd-none' : 'col-md-12 col-lg-3'}`}>
            <div className="row">
              <div className="col-12">
                <div>
                  <div className={`col-12 text-center bg-light radius-m p-5`}>
                    <div className="row">
                      <div className="col-12 col-md-9 col-lg-12 text-center text-md-start text-lg-center">
                        <h1 className="sub-title color-blue mb-2">A beautiful but long title on three rows</h1>
                        <div className="text mb-5">Tyopology - Category</div>
                      </div>
                      <div className="col-12 col-md-3 col-lg-12">
                        <div className={`${styles.profilePic}`}>
                          <UserPic image="https://source.unsplash.com/3TLl_97HNJo/480x480" />
                        </div>
                        <div className={`${styles.badges} d-flex justify-content-end`}>
                          <div className={`${styles.badge} bg-red color-light`}>
                            <FontAwesomeIcon icon={faFlask as IconProp} />
                          </div>
                          <div className={`${styles.badge} bg-green color-light`}>
                            <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="info mt-5 d-block d-md-flex d-lg-block">
                        <div className="d-flex justify-content-start mb-3 me-0 me-md-3 me-lg-0">
                          <div className="d-flex flex-column justify-content-center">
                            <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-4" />
                          </div>
                          <div className="text text-start color-blue">Karen White</div>
                        </div>
                        <div className="d-flex justify-content-start mb-3 me-0 me-md-3 me-lg-0">
                          <div className="d-flex flex-column justify-content-center">
                            <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-4" />
                          </div>
                          <div className="text text-start color-dark">Posted: 15 Mar 2023</div>
                        </div>
                        <div className="d-flex justify-content-start mb-3 me-0 me-md-3 me-lg-0">
                          <div className="d-flex flex-column justify-content-center">
                            <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-4" />
                          </div>
                          <div className="text text-start color-blue">Contest Title</div>
                        </div>
                        <div className="d-flex justify-content-start mb-3">
                          <div className="d-flex flex-column justify-content-center">
                            <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-3" />
                          </div>
                          <div className="text text-start color-blue">234 votes</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row buttons">
              <div className="col-12">
                <button className="btn-blue rounded full mt-7 mb-3">Add to favorite</button>
                <button className="btn-blue rounded full mb-3">Send message</button>
                <button className="btn-red rounded big full mb-3">Block</button>
              </div>
            </div>
            <div className="tags mt-7 d-flex flex-wrap">
              <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag mb-4">
                <span>The medium</span>
              </button>
            </div>
          </div>
          {/* Col right ============================================ */}
          <div className={`col-12 ${galleryZoom ? 'col-md-12' : 'col-md-12 col-lg-9'}`}>
            <div className="row">
              <div className="col-12 mb-7">
                <Gallery images={images} onZoom={handleGalleryZoom} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Username</div>
                  <div className="col-12 col-md-10">KarenWhite</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Name</div>
                  <div className="col-12 col-md-10">Karen Julia White</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Biography</div>
                  <div className="col-12 col-md-10">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt? Lorem ipsum dolor sit amet consectetur adipisicing elit. In omnis enim
                      molestias voluptate recusandae minus cupiditate expedita eveniet officiis quo molestiae atque,
                      numquam pariatur nihil repellendus, nesciunt aliquam quam deserunt.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod consequatur aut totam in ut sed
                      voluptas, dolor rem neque molestiae, praesentium non facere officiis blanditiis saepe sunt est
                      voluptatibus incidunt?
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Who are you</div>
                  <div className="col-12 col-md-10">A designer</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Gender</div>
                  <div className="col-12 col-md-10">She/Her</div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Website</div>
                  <div className="col-12 col-md-10">
                    <a href="#">http://www.thisisatestlink.com</a>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Social Network</div>
                  <div className="col-12 col-md-10">
                    <a href="#">http://www.thisisatestlink.com</a>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div>
              </div>
            </div>
            {/* Discussion ================================================================ */}
            <div className="row">
              <div className="col-12">
                <Conversation messages={mockMessages} author={mockAuthor} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Design;
