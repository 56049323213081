import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import SplitSection from '../components/SplitSection/SplitSection';
import HorizontalSection from '../components/HorizontalSection/HorizontalSection';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCreativesPages } from '../mockdata/forCreativesPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import creative from '../assets/creative_placeholder.png';
import Slider from '../components/Slider/Slider';
import CardLarge from '../components/CardLarge/CardLarge';

const InfoCreatives: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Info for Creatives"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCreativesPages} />

      <SplitSection
        bgColorLeft="#ffffff"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Get in contact with the best worldwide brands</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
            <div className="col-12">
              <button className="btn-dark rounded mt-7">Create your account</button>
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <HorizontalSection bgColor="#ededed">
        <section className="container py-7">
          <div className="row">
            <div className="col-12 text-center pb-7 pt-3">
              <h3 className="bold-title color-dark pb-3">Why desall</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
              <h3 className="text-xl fw-bold pt-5 pb-3">Be part of a community</h3>
              <p className="text">
                Access to a community of creatives coming from all around the world, with whom one can share the passion
                for design.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
              <h3 className="text-xl fw-bold pt-5 pb-3">Get noticed</h3>
              <p className="text">International awareness generated by the interest of designers and professionals.</p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
              <h3 className="text-xl fw-bold pt-5 pb-3">Create and expand your portfolio</h3>
              <p className="text">
                Chance to create a quality portfolio based on real-life scenarios, by taking part in the entire creative
                process.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3 px-4">
              <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
              <h3 className="text-xl fw-bold pt-5 pb-3">Get rewarded</h3>
              <p className="text">
                Chance to get in touch with the best companies on the market, while running for cash awards and
                royalties.
              </p>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <section className="container py-7">
        <div className="row">
          <div className="col-12 text-center pb-7 pt-3">
            <h3 className="bold-title color-dark pb-3">Come Funziona Desall</h3>
            <h4 className="sub-title color-light-gray-3 pb-3">
              Entra a far parte della nostra community e partecipa ai nostri contest!
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">
              Crea e attiva
              <br /> il tuo account
            </h3>
            <p className="text ">
              Access to a community of creatives coming from all around the world, with whom one can share the passion
              for design.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3 ">Scopri i nostri contest creativi e inizia a progettare</h3>
            <p className="text ">International awareness generated by the interest of designers and professionals.</p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">
              Carica <br /> il tuo progetto
            </h3>

            <p className="text">
              Chance to create a quality portfolio based on real-life scenarios, by taking part in the entire creative
              process.
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center pb-3 px-4">
            <img className="img-270 fit-contain" src={factory} alt="Placeholder" />
            <h3 className="text-xl fw-bold pt-5 pb-3">
              Condividi <br /> il tuo progetto
            </h3>

            <p className="text">
              Chance to get in touch with the best companies on the market, while running for cash awards and royalties.
            </p>
          </div>
        </div>
      </section>

      <HorizontalSection bgColor="#ededed">
        <section className="container py-7">
          <div className="row">
            <div className="col-12 text-center pb-7 pt-3">
              <h3 className="bold-title color-dark pb-3">Two ways</h3>
              <h4 className="sub-title color-light-gray-3 pb-5">Smaller subtitle</h4>
              <p className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis minus dolorum dignissimos eligendi
                quam architecto corrupti repellat illum officia, tempora iusto impedit tenetur possimus exercitationem
                molestiae beatae velit? Reiciendis, tenetur!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 text-center">
              <img
                className="img-550 fit-cover"
                alt="Placeholder"
                src="https://source.unsplash.com/collection/1326207/800x800"
              />
              <h2 className="bigger-text color-dark mt-4">Open call</h2>
              <p className="text-xl fw-bold pt-5 pb-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
              <p className="text mb-5">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur voluptates consequuntur ratione nemo
                asperiores consectetur suscipit similique temporibus beatae, impedit quaerat deleniti, repellendus
                sapiente saepe quia fugiat velit commodi natus.
              </p>
            </div>
            <div className="col-12 col-md-6 text-center">
              <img
                className="img-550 fit-cover"
                alt="Placeholder"
                src="https://source.unsplash.com/collection/9459045/800x800"
              />
              <h2 className="bigger-text color-dark mt-4">Open call</h2>
              <p className="text-xl fw-bold pt-5 pb-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
              <p className="text mb-5">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur voluptates consequuntur ratione nemo
                asperiores consectetur suscipit similique temporibus beatae, impedit quaerat deleniti, repellendus
                sapiente saepe quia fugiat velit commodi natus.
              </p>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <section className="my-7 mt-10">
        <div className="container mb-7">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="bold-title color-blue text-center pb-3">Last contests</h3>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <Slider timeInterval={2500}>
            <CardLarge
              topText="Client: SAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/8817103/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: FAME"
              bottomText="Project: Brand revamp"
              backGround="https://source.unsplash.com/collection/1215308/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: TAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/5008076/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: GAME"
              bottomText="Project: Brand identity redesign"
              backGround="https://source.unsplash.com/collection/9572993/480x480"
            ></CardLarge>
          </Slider>
        </div>
      </section>

      <HorizontalSection bgColor="#3b3c3c">
        <section className="container py-7">
          <div className="row justify-content-center">
            <div className="col-12 text-center pb-7 pt-3">
              <h3 className="bold-title color-light pb-3">Awards don't pay bills</h3>
            </div>
            <div className="col-8 d-flex justify-content-center">
              <img className="img-270 fit-contain" src={creative} alt="Placeholder" />
            </div>
            <div className="col-12 text-center pb-7 pt-3">
              <p className="text-xl fw-bold color-light">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. <br />
                Reiciendis minus dolorum dignissimos eligendi quam architecto corrupti repellat illum officia, <br />
                tempora iusto impedit tenetur possimus exercitationem molestiae beatae velit? Reiciendis, tenetur!
              </p>
              <p className="text color-light pt-5">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam fugiat laboriosam consequatur quia,
                accusantium earum aut ab est aliquid, corporis at nihil et molestiae! Beatae, excepturi. Voluptatibus
                aut. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident dicta hic ducimus sit
                quibusdam, alias quod numquam perspiciatis libero delectus ratione accusamus dolores, esse ab
                voluptatibus deserunt aperiam molestias porro.
              </p>
              <button className="btn-light rounded mt-7">Learn more</button>
            </div>
          </div>
        </section>
      </HorizontalSection>

      <SplitSection
        bgColorLeft="#ededed"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">
                Do you need any support?
                <br />
                Please write to us
              </h1>
            </div>
            <div className="col-10">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
            <div className="col-12">
              <button className="btn-dark rounded mt-7">Get in touch</button>
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <HorizontalSection bgColor="#ededed">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0 pb-5">
              <input type="text" name="text" placeholder="Username" className="inline w-md-25 me-4 mb-3 mb-md-0" />
              <input type="text" name="text" placeholder="Password" className="inline w-md-25 me-4 mb-3 mb-md-0" />
              <button className="btn-dark rounded">Create your account</button>
            </div>
          </div>
        </div>
      </HorizontalSection>

      <SplitSection
        bgColorLeft="#ffffff"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">
                “Lorem ipsum dolor sit amet, conse ctetur adipisci elit, sed do eiusmod tempor.”
              </h1>
            </div>
            <div className="col-12 text-xl fw-bold ">Carolina from Brasil, winner of the Wirlpool contest</div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pt-md-5 d-flex justify-content-center">
              <img
                src="https://source.unsplash.com/collection/9347621/480x480"
                className="img-270 circle"
                alt="Placeholder"
              />
            </div>
          </div>
        }
      />

      <SplitSection
        bgColorLeft="#ffffff"
        itemRight={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">
                “Lorem ipsum dolor sit amet, conse ctetur adipisci elit, sed do eiusmod tempor.”
              </h1>
            </div>
            <div className="col-12 text-xl fw-bold ">Tony from UK, winner of the Elica contest</div>
          </div>
        }
        itemLeft={
          <div className="row">
            <div className="col-12 px-5 pt-md-5 d-flex justify-content-center">
              <img
                src="https://source.unsplash.com/collection/2347777/480x480"
                className="img-270 circle"
                alt="Placeholder"
              />
            </div>
          </div>
        }
      />

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default InfoCreatives;
