import React from 'react';
import styles from './SplitSectionImage.module.scss';

interface ISplitSectionImage {
  bgImage?: string;
  content?: React.ReactNode;
  bgColor?: string;
  skewed?: boolean;
  reverse?: boolean;
}

/**
 *
 * @param props
 * @returns
 */
const SkewSection: React.FC<ISplitSectionImage> = (props) => {
  const ImageItem = () => {
    return (
      <div className={`col-12 col-md-6 p-0 ${styles.imgSectionWrapper}`}>
        <div className={`${styles.imgSection}`} style={{ backgroundImage: `url(${props.bgImage})` }}></div>
      </div>
    );
  };

  const ContentItem = () => {
    return (
      <div
        className={`col-12 col-md-6 d-flex ${props.reverse ? 'justify-content-end' : 'justify-content-start'} ${
          styles.contentSectionWrapper
        }`}
        style={{
          backgroundColor: `${props.bgColor ?? 'transparent'}`,
        }}
      >
        <div className={`py-7 px-3 ${styles.halfSection} ${styles.contentSection}`}>{props.content}</div>
      </div>
    );
  };

  return (
    <section
      className={`container-fluid overflow-hidden ${styles.splitSectionImageWrapper}`}
      style={{
        backgroundColor: `${props.bgColor ?? 'transparent'}`,
      }}
    >
      <div
        className={`row ${styles.splitSectionContentWrapper} ${props.skewed && styles.skewed} ${
          props.reverse && styles.reverse
        }`}
      >
        {props.reverse ? (
          <>
            <ContentItem />
            <ImageItem />
          </>
        ) : (
          <>
            <ImageItem />
            <ContentItem />
          </>
        )}
      </div>
    </section>
  );
};

export default SkewSection;
