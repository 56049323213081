import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import SplitSection from '../components/SplitSection/SplitSection';
import HorizontalSection from '../components/HorizontalSection/HorizontalSection';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import { topNavPages } from '../mockdata/navPages';
import { forCreativesPages } from '../mockdata/forCreativesPages';
import PageNavigation from '../components/PageNavigation/PageNavigation';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';

//Assets
import factory from '../assets/factory_placeholder.png';
import creative from '../assets/creative_placeholder.png';
import Slider from '../components/Slider/Slider';
import CardLarge from '../components/CardLarge/CardLarge';
import SplitSectionImage from '../components/SplitSectionImage/SplitSectionImage';
import CardSmall from '../components/CardSmall/CardSmall';

const DreamTeam: React.FC = () => {
  return (
    <>
      <AppHeader
        links={topNavPages}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Info for Creatives"
      />
      <PageNavigation bgColor="#E3E3E3" links={forCreativesPages} />

      <HorizontalSection>
        <div className="row my-5">
          <div className="col-12 d-flex justify-content-center">
            <img className="img-550 fit-cover" alt="Placeholder" src={creative} />
          </div>
        </div>
      </HorizontalSection>

      <HorizontalSection bgColor="#ededed">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h2 className="bold-title color-dark">Dream Team</h2>
            <h3 className="sub-title color-dark">Subtitle</h3>

            <p className="bigger-text py-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua.
            </p>
            <p className="text-xl fw-bold pt-5 pb-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore
              magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut.
            </p>
            <p className="text pt-5 pb-3 color-dark">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi corporis voluptates dolor nulla iure
              accusantium perspiciatis qui magni explicabo excepturi id quidem, distinctio consequuntur autem eos minima
              ab accusamus fugit? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates explicabo, ad
              odit ducimus doloribus corporis hic ipsam iste magni culpa quidem cumque facere nisi consequuntur.
              Dignissimos autem voluptatem consequuntur in!
            </p>
          </div>
        </div>
      </HorizontalSection>

      <section className="container my-7">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <h3 className="bold-title color-dark text-center pb-5">Some of our Dream Team creatives</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Marlo Basita"
              bottomText="Atlanta"
              backGround="https://source.unsplash.com/collection/9347621/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Judith Emerson"
              bottomText="London"
              backGround="https://source.unsplash.com/collection/2347777/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Carlo Esposito"
              bottomText="Madrid"
              backGround="https://source.unsplash.com/collection/2219444/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Wang Yìhán"
              bottomText="Hong Kong"
              backGround="https://source.unsplash.com/collection/170856/480x480"
            ></CardSmall>
          </div>
        </div>
      </section>

      <SplitSection
        bgColorLeft="#ededed"
        itemLeft={
          <div className="row h-100 d-flex flex-column justify-content-center">
            <div className="col-12">
              <h1 className="bold-title color-dark mb-5">Desall reputation</h1>
            </div>
            <div className="col-12">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
              Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem adipisci
              eveniet enim illo.
            </div>
            <div className="col-12">
              <button className="btn-dark rounded mt-7">Get in touch</button>
            </div>
          </div>
        }
        itemRight={
          <div className="row">
            <div className="col-12 px-5 pb-5">
              <img src={factory} className="img-fluid" alt="Placeholder" />
            </div>
          </div>
        }
      />

      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default DreamTeam;
