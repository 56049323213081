import React from 'react';

//Header Data
import headerLogo from './assets/desall_logo.png';
import { topNavPages } from './mockdata/navPages';

//Footer Data
import footerImg from './assets/footer_placeholder_transparent.png';

//Assets
import roundpic from './assets/roundpic_placeholder.png';
import factory from './assets/factory_placeholder.png';
import creative from './assets/creative_placeholder.png';
import awards from './assets/awards_placeholder.png';
//import backVideo from './assets/hero-video-test.mp4';

import './App.scss';

//Components
import AppHeader from './components/AppHeader/AppHeader';
import AppFooter from './components/AppFooter/AppFooter';
import PageNavigation from './components/PageNavigation/PageNavigation';
import HeroSection from './components/HeroSection/HeroSection';
import HorizontalSection from './components/HorizontalSection/HorizontalSection';
import SplitSection from './components/SplitSection/SplitSection';
import Slider from './components/Slider/Slider';
import CardSmall from './components/CardSmall/CardSmall';
import CardLarge from './components/CardLarge/CardLarge';
import SplitSectionImage from './components/SplitSectionImage/SplitSectionImage';

const App: React.FC = () => {
  return (
    <>
      <AppHeader links={topNavPages} bgColor="#7997c5" logoImg={headerLogo} bgImage={creative} pageTitle="Home" />
      <PageNavigation bgColor="#E3E3E3" links={topNavPages} />
      <HeroSection
        minHeight="70vh"
        alpha={0.6}
        bgColor="#304053"
        bgImage="https://source.unsplash.com/collection/401930/1440x900"
        bgVideo="https://assets.codepen.io/6093409/river.mp4"
        //bgVideo={`${process.env.PUBLIC_URL}/hero-video-test.mp4`}
      >
        <h1 className="bold-title color-light">We are Desall</h1>
        <h3 className="sub-title color-light">Here you can find a short payoff</h3>
      </HeroSection>
      <HorizontalSection bgColor="#ededed">
        <div className="row justify-content-center">
          <div className="col-10 text-center py-7">
            <h3 className="bold-title color-blue">Think open Think good</h3>
            <p className="bigger-text py-3">
              Desall is an Italian leading company in providing digital services dedicated to innovation and
              participatory design.
            </p>
            <p className="sub-title pt-3 pb-7">
              Our main goal is to create value for our clients, using an approach that goes beyond the product itself,
              always aiming to innovate. We encourage our clients to evolve their brands while keeping a strong bond
              with their identity.
            </p>
            <button className="btn-blue rounded">Get in touch</button>
          </div>
        </div>
      </HorizontalSection>
      {/* Our values */}
      <section className="container py-7">
        <div className="row">
          <div className="col-12 text-center pb-7">
            <h3 className="bold-title color-blue">Our values</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <img className="img-270" src={roundpic} alt="Placeholder" />
            <h3 className="text-xxl fw-bold pt-5 pb-3">Open approach</h3>
            <p className="text px-2">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio ut commodi modi nobis ipsa quis velit ab
              iste dicta, fugiat
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <img className="img-270" src={roundpic} alt="Placeholder" />
            <h3 className="text-xxl fw-bold pt-5 pb-3">Open approach</h3>
            <p className="text px-2">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio ut commodi modi nobis ipsa quis velit ab
              iste dicta, fugiat
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <img className="img-270" src={roundpic} alt="Placeholder" />
            <h3 className="text-xxl fw-bold pt-5 pb-3">Open approach</h3>
            <p className="text px-2">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio ut commodi modi nobis ipsa quis velit ab
              iste dicta, fugiat
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <img className="img-270" src={roundpic} alt="Placeholder" />
            <h3 className="text-xxl fw-bold pt-5 pb-3">Open approach</h3>
            <p className="text px-2">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odio ut commodi modi nobis ipsa quis velit ab
              iste dicta, fugiat
            </p>
          </div>
        </div>
      </section>

      {/* Split section */}
      <SplitSection
        bgColorLeft="#E5E5E5"
        bgColorRight="#8B9CD0"
        itemLeft={
          <div>
            <div className="row">
              <div className="col-12">
                <h1 className="bold-title color-blue text-center">For companies</h1>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-12 col-lg-6 text color-dark">
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis adipisci ea sed reiciendis
                  temporibus, quas nam pariatur minus natus id est deleniti repellendus, alias expedita omnis quia
                  accusamus. Hic, veritatis.
                </p>
              </div>
              <div className="col-12 col-lg-6  d-flex justify-content-center justify-content-lg-end">
                <img className="img-270" src={factory} alt="Placeholder" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <button className="btn-blue rounded">Get in touch</button>
              </div>
            </div>
          </div>
        }
        itemRight={
          <div>
            <div className="row">
              <div className="col-12">
                <h1 className="bold-title color-light text-center">For creatives</h1>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-12 col-lg-6 text color-light">
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis adipisci ea sed reiciendis
                  temporibus, quas nam pariatur minus natus id est deleniti repellendus, alias expedita omnis quia
                  accusamus. Hic, veritatis.
                </p>
              </div>
              <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start">
                <img className="img-270" src={creative} alt="Placeholder" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <button className="btn-light rounded">Get in touch</button>
              </div>
            </div>
          </div>
        }
      />

      <section>
        <div className="container my-7">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="bold-title color-blue text-center pb-5">Projects on the market</h3>
              <div className="col-12 text-center sub-title">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat quibusdam modi sunt minus optio
                aliquam molestiae incidunt, odio eos iure ad odit doloremque! Earum odit molestias similique vel
                repellendus qui!
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <Slider timeInterval={2500}>
            <CardLarge
              topText="Small Card"
              bottomText="Card size 370x370"
              backGround="https://source.unsplash.com/collection/1079579/480x480"
            ></CardLarge>
            <CardLarge
              topText="Small Card"
              bottomText="Card size 370x370"
              backGround="https://source.unsplash.com/collection/928423/480x480"
            ></CardLarge>
            <CardLarge
              topText="Small Card"
              bottomText="Card size 370x370"
              backGround="https://source.unsplash.com/collection/1677156/480x480"
            ></CardLarge>
          </Slider>
        </div>
      </section>

      <section className="container my-7">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <h3 className="bold-title color-dark text-center">Find the best brands</h3>
            <h4 className="sub-title color-light-gray-3 text-center pb-5">active contests</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Client: SAME"
              bottomText="Total award: € 6000"
              backGround="https://source.unsplash.com/collection/h_go4RaSWiE/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Client: SAME"
              bottomText="Total award: € 6000"
              backGround="https://source.unsplash.com/collection/43455101/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Client: SAME"
              bottomText="Total award: € 6000"
              backGround="https://source.unsplash.com/collection/618454/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Client: SAME"
              bottomText="Total award: € 6000"
              backGround="https://source.unsplash.com/collection/98476275/480x480"
            ></CardSmall>
          </div>
        </div>
      </section>

      <section className="container my-7">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <h3 className="bold-title color-dark text-center">Find the best creatives</h3>
            <h4 className="sub-title color-light-gray-3 text-center pb-5">users of the month</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Marlo Basita"
              bottomText="Atlanta"
              backGround="https://source.unsplash.com/collection/9347621/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Judith Emerson"
              bottomText="London"
              backGround="https://source.unsplash.com/collection/2347777/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Carlo Esposito"
              bottomText="Madrid"
              backGround="https://source.unsplash.com/collection/2219444/480x480"
            ></CardSmall>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-center d-flex flex-column align-items-center pb-3">
            <CardSmall
              topText="Wang Yìhán"
              bottomText="Hong Kong"
              backGround="https://source.unsplash.com/collection/170856/480x480"
            ></CardSmall>
          </div>
        </div>
      </section>

      <section className="my-7 mt-10">
        <div className="container mb-7">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="bold-title color-blue text-center pb-3">Our clients</h3>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <Slider timeInterval={2500}>
            <CardLarge
              topText="Client: SAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/h_go4RaSWiE/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: FAME"
              bottomText="Project: Brand revamp"
              backGround="https://source.unsplash.com/collection/43455101/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: TAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/618454/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: GAME"
              bottomText="Project: Brand identity redesign"
              backGround="https://source.unsplash.com/collection/98476275/480x480"
            ></CardLarge>
          </Slider>
        </div>
      </section>

      <section className="my-7 mt-10">
        <div className="container mb-7">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="bold-title color-blue text-center pb-3">Talk about us</h3>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <Slider timeInterval={2500}>
            <CardLarge
              topText="Client: SAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/8817103/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: FAME"
              bottomText="Project: Brand revamp"
              backGround="https://source.unsplash.com/collection/1215308/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: TAME"
              bottomText="Project: Brand redesign"
              backGround="https://source.unsplash.com/collection/5008076/480x480"
            ></CardLarge>
            <CardLarge
              topText="Client: GAME"
              bottomText="Project: Brand identity redesign"
              backGround="https://source.unsplash.com/collection/9572993/480x480"
            ></CardLarge>
          </Slider>
        </div>
      </section>

      <section className="mt-10">
        <SplitSectionImage
          skewed
          bgColor="#7997c5"
          bgImage={awards}
          content={
            <div className="row py-7">
              <div className="col-12">
                <h1 className="bold-title color-light">SZIDF 2019 Award ceremony</h1>
              </div>
              <div className="col-12 text color-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga inventore quia explicabo modi debitis?
                Voluptatum tempore dolor quidem animi laborum voluptate ullam et, aspernatur tenetur, voluptatem
                adipisci eveniet enim illo.
              </div>
              <div className="col-12 pt-7 pb-3">
                <button className="btn-light rounded">Learn more</button>
              </div>
            </div>
          }
        />
      </section>
      <AppFooter links={topNavPages} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default App;
